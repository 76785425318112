import { Injectable } from '@angular/core';
import { ListUrl } from '../constantes/request';
import { AuthService } from '../auth/auth.service';
import { Url } from './url';



@Injectable({
  providedIn: 'root'
})
export class UrListService {

  listUrl = ListUrl;

  constructor(public auth: AuthService) { }

  url(value: string): Url {
    return new Url(this.listUrl[value], this);
  }

}
