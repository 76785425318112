import { Injectable } from '@angular/core';

import { AuthService } from 'src/app/core/auth/auth.service';
import { Config2 } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import { Cache } from 'src/app/core/utils/cache';
import * as moment from 'moment-timezone';
import { session } from 'src/app/core/constantes/sessiones';
import { EnterpriseService } from 'src/app/n360/enterprise-panel/enterprise.service';
import Ajax2 from '../utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class InitDateService {

  interval: any;
  zonashorarias: any = [];
  seconds = 0;
  error: boolean = false;

  constructor(private listUrl: UrListService, public auth: AuthService, public enterpriseService: EnterpriseService,) { }

  async getDate(id, tz) {

    let usr = Cache.getLocal(session.user, true);
    if (usr != null && usr != undefined) {
      if (id == usr.idsucursal) {
        usr.zona_horaria_id = tz;
        Cache.setLocal(session.user, usr);
        if (this.zonashorarias.length == 0) {
          await this.get_zona_horaria().then((resp) =>{
            this.beginProcess();
          });
        } else {
          this.beginProcess();
        }
      }
    }
  }

  beginProcess() {
    let usr = Cache.getLocal(session.user, true);
    let zone_name = this.zonashorarias.find(x => x.zona_horaria_id == usr.zona_horaria_id).tz_name;
    const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('init_date'),
        auth: this.auth,
        body: {tz: zone_name}
      };
      const configAjax: Config2 = {
        alerts: false
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then((reponse) => {
        
        this.setDate(reponse.entities);
        this.seconds = 0;
        this.error = false;

      }).catch(error => {
        this.error = false;
      });
  }

  startIntervalTime() {
    let self = this;
    this.interval = setInterval(function() {
      let cache = Cache.getLocal(session.init_date, true);
      if (cache != null && cache != undefined) {
        let pass = true;
        let fecha1 = moment(cache.date.date, "YYYY-MM-DD HH:mm:ss");
        let fecha2 = moment(moment().tz(cache.date.timezone).format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
        let sec = fecha2.diff(fecha1, 's'); // Diff in sec
        let resl= (Number(sec) - Number(this.seconds));

        if ((resl >= 3600 || resl <= -3600) && !self.error) {
          pass = false;
          self.error = true;
          if (this.zonashorarias != null && this.zonashorarias != undefined) {
            if (this.zonashorarias.length == 0) {
              self.get_zona_horaria().then((resp) =>{
                self.beginProcess();
              });
            } else {
              self.beginProcess();
            }
          } else {
            self.get_zona_horaria().then((resp) =>{
              self.beginProcess();
            });
          }
        }
        if (pass) {
          this.seconds = sec;
          cache.strdate = fecha1.add(this.seconds, 's').format("YYYY-MM-DD HH:mm:ss");
          cache.UTC = moment(cache.strdate).tz('UTC').format("YYYY-MM-DD HH:mm:ss");
          Cache.setLocal(session.init_date, cache);
        }
      }
    }, 5000);
  }

  get_zona_horaria() {
    return new Promise((resolve, reject) => {
      const config : Config2 = {
        alerts: false,
        retry: true
      }
      this.enterpriseService.set_data('get_zona_horaria', {}, (resp)=>{
        if (resp.success) {
          this.zonashorarias = resp.zonashorarias;
        }
        resolve(resp);
      }, config);
    });
  }

  getDateTZ(tz) {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_date_tz'),
        auth: this.auth,
        body: {tz: tz}
      };
      const configAjax: Config2 = {
        alerts: false
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then((reponse) => {
        resolve(reponse.entities.strdate);
      }).catch(error => {
        reject(error);
      });
    });
  }

  setDate(data: any) {
    let obj = {
      date: {
        date: data.date.date,
        timezone_type: data.date.timezone_type,
        timezone: data.date.timezone
      },
      strdate: data.strdate,
      UTC: data.UTC
    };
    Cache.setLocal(session.init_date, obj);
  }
}
