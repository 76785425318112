import { session, inndb } from "../constantes/sessiones";

export class Cache {

    static makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    static get local() {
        return localStorage;
    }

    static get session() {
        return sessionStorage;
    }

    static get indexedDB() {
        return window.indexedDB;
    }

    static get IDBTransaction() {
        return IDBTransaction = window.IDBTransaction;
    }

    static get IDBKeyRange(){
        return IDBKeyRange = window.IDBKeyRange;
    }

    static requireString(value: string) {
        if (typeof value === 'string') {
            return true;
        } else {
            throw Error('Se requiere que el valor sea un string');
        }
    }

    static requireObject(value: string) {
        if (typeof value === 'object') {
            return true;
        } else {
            throw Error('Se requiere que el valor sea un object');
        }
    }

    static getLocal(name: string, json: boolean = false) {
        Cache.requireString(name);
        if (Cache.local.getItem(name) == 'undefined') {
            Cache.removeLocalItem(name);
        }
        if (json) {
            if( Cache.local.getItem(name)){
                return JSON.parse(Cache.local.getItem(name));
            }
            else{
                return null;
            }
        } else {
            return Cache.local.getItem(name);
        }
    }

    static check_support_indexedDB() {
        return Cache.indexedDB;
    }

    static getDbv(){
        if (Cache.getLocal(session.innodbv)) {
            return Cache.getLocal(session.innodbv, true);
        }else{
            return 1;
        }
    }

    static setDbv(dbv){
        Cache.setLocal(session.innodbv, dbv);
    }

    static delete_db(name:any=false) {
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            const DBDeleteRequest = Cache.indexedDB.deleteDatabase(name);
    
            DBDeleteRequest.onerror = function(event) {
                console.log("Error inno007: Error al eliminar la base de datos");
                reject("Error deleting database.");
            };
    
            DBDeleteRequest.onsuccess = function(event) {
                console.log("Database deleted successfully");
                resolve("Database deleted successfully");
            };
            DBDeleteRequest.onblocked = function (event) {
                console.log("Error inno006: Base de datos bloqueada. Intentando desbloquear...");
                try {
                    (event.target as any).result.close();
                } catch (error) {
                    console.log("Error inno008: Error al desbloquear");
                    reject(error);
                }
            };
        });
        
    }

    
    static create_db(name:any=false, version=0, objectstore='objetos', key='ssn') {
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            if (version == 0) {
                version = Cache.getDbv();
            }
            let request = Cache.indexedDB.open(name, version);
            request.onerror = function(event) {
                console.log(event);
                console.log("Error inno001: No pudo crearse la base de datos");
                reject(false);
            };
            request.onsuccess = function(event: any) {
                try {
                    let db = event.target.result;
                    resolve(db);
                    db.close();
                } catch (error) {
                    return event.target.onerror(error);
                }
            };
            request.addEventListener('upgradeneeded', (event: any) => {
                let db = event.target.result;
                let objectStore = db.createObjectStore(objectstore, { keyPath: key });
                objectStore.transaction.oncomplete = function(event) {
                    
                };
            });
        });
        
    }

    static exist_table(name:any=false, version=0, objectstore='objetos'){
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            if (version == 0) {
                version = Cache.getDbv();
            }
            let request = Cache.indexedDB.open(name, version);
            request.onerror = function(event) {
                console.log(event);
                console.log("Error inno005: No existe");
                reject(false);
            };
            request.onsuccess = function(event: any) {
                let db = event.target.result;
                try {
                    db.transaction(objectstore, "readwrite").objectStore(objectstore);
                    resolve(true);
                    db.close();
                } catch (error) {
                    return event.target.onerror(error);
                }
            };
        });
    }

    static save_data_db(key, obj, name:any=false, version=0, objectstore='objetos') {
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            if (version == 0) {
                version = Cache.getDbv();
            }
            let request = Cache.indexedDB.open(name, version);
            request.onerror = function(event) {
                console.log("Error inno003: No se pudo guardar datos");
                reject(false);
            };
            request.onsuccess = function(event: any) {
                try {
                    let db = event.target.result;
                    var transaction = db.transaction([objectstore], "readwrite");
                    var objectStore = transaction.objectStore(objectstore);
                    transaction.oncomplete = (eve) => {
                        //console.log('Complete Transaction');
                    }
                    obj['ssn'] = key;
                    var res = objectStore.get(key);
                    res.onsuccess = (eve) => {
                        if (typeof eve.target.result === 'undefined') {
                            objectStore.add(obj);
                            resolve(obj);
                        } else {
                            objectStore.put(obj);
                            resolve(obj);
                        }
                        db.close();
                    };
                } catch (error) {
                    return event.target.onerror(error);
                }
            };
        });
    }

    static get_data_db(key, name:any=false, version=0, objectstore='objetos') {
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            if (version == 0) {
                version = Cache.getDbv();
            }
            let request = Cache.indexedDB.open(name, version);
            request.onerror = function(event) {
                console.log("Error inno004: No se pudo obtener datos");
                reject(false);
            };
            request.onsuccess = function(event: any) {
                try{
                    let db = event.target.result;
                    var transaction = db.transaction([objectstore], "readwrite");
                    var objectStore = transaction.objectStore(objectstore);
                    transaction.oncomplete = (eve) => {
                        //console.log('Complete Transaction');
                    }
                    var res = objectStore.get(key);
                    res.onsuccess = (eve) => {
                        if (typeof eve.target.result !== 'undefined') {
                            resolve(eve.target.result);
                        } else {
                            resolve(false);
                        }
                        db.close();
                    };
                } catch (error) {
                    return event.target.onerror(error);
                }
            };
        });
    }

    static rm_data_db(key, name:any=false, version=0, objectstore='objetos') {
        return new Promise((resolve, reject) => {
            if(name == false){
                name = inndb.namedb;
            }
            if (version == 0) {
                version = Cache.getDbv();
            }
            let request = Cache.indexedDB.open(name, version);
            request.onerror = function(event) {
                console.log("Error inno002: No se pudo eliminar la data");
                reject(false);
            };
            request.onsuccess = function(event: any) {
                try{
                    let db = event.target.result;
                    var transaction = db.transaction([objectstore], "readwrite");
                    var objectStore = transaction.objectStore(objectstore);
                    transaction.oncomplete = (eve) => {
                        //console.log('Complete Transaction');
                    }
                    var res = objectStore.get(key);
                    res.onsuccess = (eve) => {
                        if (typeof eve.target.result !== 'undefined') {
                            objectStore.delete(key);
                        } else {
                            resolve(false);
                        }
                        db.close();
                    };
                } catch (error) {
                    return event.target.onerror(error);
                }
            };
        });
    }

    static setLocal(name: string, value: any) {
        Cache.requireString(name);
        try {
            Cache.requireObject(value);
            Cache.local.setItem(name, JSON.stringify(value));
        } catch (error) {
            Cache.local.setItem(name, value);
        }
    }

    static getSession(name: string , json: boolean = false) {
        Cache.requireString(name);
        if (Cache.session.getItem(name) == 'undefined') {
            Cache.removeSessionItem(name);
        }
        if (json) {
            return JSON.parse(Cache.session.getItem(name));
        } else {
            return Cache.session.getItem(name);
        }
    }

    static setSession(name: string, value: any) {
        Cache.requireString(name);
        try {
            Cache.requireObject(value);
            Cache.session.setItem(name, JSON.stringify(value));
         } catch (error) {
             Cache.session.setItem(name, value);
         }
    }

    static getItem(name: string, json: boolean = false) {
        if (Cache.getSession(name)) {
            return Cache.getSession(name, json);
        } else if (Cache.getLocal(name)) {
            return Cache.getLocal(name, json);
        } else {
            throw Error('El item no se encuentra en ningun Storage');
        }
    }

    static removeLocalItem(name: string) {
        /**
         *  Remueve un item de local
         */
        Cache.requireString(name);
        Cache.local.removeItem(name);
    }
    static removeSessionItem(name: string) {
        /**
         * Remueve un item de session
         */
        Cache.requireString(name);
        Cache.session.removeItem(name);
    }

    static removeItem(name: string) {
        /**
         * remueve un item en cualquier storage
         */
        Cache.removeLocalItem(name);
        Cache.removeSessionItem(name);
    }

    static deleteAllCookies() {
        const cookies = document.cookie.split(';');
        for (const i of cookies) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    }

    static setCookieEncode(value: string) {
        // Cache.deleteAllCookies()
        document.cookie = value;
    }

    static setCookie(name: string, value: string) {
        /**
         * Guarda Una Cookie para luego ser enviada en las peticiones del navegador
         */
        Cache.setCookieEncode(`${name}=${value}; path=/;`);
    }

    static changeCookie(name: string, value: string) {
        /**
         * Cambiar una Cookie
         */
        Cache.setCookie(name, value);
    }

    static getPathName(name: string): Promise<Array<string>> {
        return new Promise((success, error) => {
            const path = window.location.pathname.split('/');
            if (path.length) {
                const existPath = path.filter(pa => pa === name );
                if (existPath.length) {
                    success(existPath);
                } else {
                    error('el pathname no existe');
                }
            } else {
                error('no hay patname');
            }
        });
    }

    static delete_cookie(name: string) {
        /**
         * Borra una cookie colocandole una fecha de expiracion
         */
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    static logout() {
        Cache.removeItem('token');
        Cache.removeItem('tokenrefres');
        Cache.removeItem('inicars');
        Cache.delete_cookie('tkauto');
        window.location.replace(`${window.location.origin}/signin`);
    }

    static getCookie(name: string): null | string {
        let cookieValue: null | string = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (const i of cookies) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === `${name}=`) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
     }     
}
