import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { session } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';
@Injectable()
    
export class ExcelService {
    campos:any;
    cabecera: any;
    tipo: any;
    opcion=null;
    DAY  = moment(Cache.getLocal(session.init_date, true).strdate).format('YYYYMMDD_HHmmss');

    campo_cliente= ['CODIGO_SAP', 'CODIGO_DE_CLIENTE', 'RIF_DEL_CLIENTE', 'RAZON_SOCIAL', 'DIRECCION_FISCAL','REPORTE_0', 'NOMBRE_DE_LA_CIUDAD', 'DNN_NESTLE', 'TELEFONO', 'REPORTE_BLANK', 'REPORTE_VE', 'REPORTE_BLANK' ,'FECHA_DE_CREACION', 'WAREHOUSE_NESTLE','REPORTE_BLANK','REPORTE_BLANK','CODIGO_DE_CLIENTE','REPORTE_BLANK','REPORTE_BLANK','REPORTE_BLANK','REPORTE_BLANK','REPORTE_BLANK','REPORTE_BLANK','REPORTE_BLANK', 'TIPO_DE_CLIENTE','REPORTE_BLANK', 'FECHA_DE_CREACION','REPORTE_0','REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK',  'REPORTE_2','REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK','REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK', 'REPORTE_BLANK',   'CODIGO_DE_RUTA_ASIGNADA', 'NOMBRE_DE_VENDEDOR_ASIGNADO', 'CODIGO_DE_RUTA_ASIGNADA', 'NOMBRE_DE_VENDEDOR_ASIGNADO','FRECUENCIA_DE_VISITA','SE_REPORTA','SE_REPORTA','SE_REPORTA','SE_REPORTA','SE_REPORTA'];
    
    campo_facturas= ['CODIGO_SAP', 'CODIGO_DE_CLIENTE', 'CODIGO_DE_CLIENTE', 'REPORTE_BLANK', 'TIPO_FACTURA', 'CODIGO_DE_RUTA_ASIGNADA', 'NRO_DOCUMENTO', 'FECHA_DOCUMENTO', 'CODIGO_NESTLE_DEL_PRODUCTO', 'UNIDADES_VENDIDAS', 'REPORTE_EA', 'TOTAL_PRODUCTO', 'PRECIO_DE_VENTA_EN_UNIDADES', 'REPORTE_0', 'REPORTE_0', 'CONTADOR_DE_FACTURA', 'TIPO_DE_DOCUMENTO','REPORTE_BLANK','REPORTE_BLANK', 'Sales_area','CODIGO_DE_RUTA_ASIGNADA', 'WAREHOUSE_NESTLE'];
    
    campo_producto= ['CODIPROD', 'SOHValue1', 'SaleValue1', 'CODINESTLE', 'Cantidad', 'unidcaja', 'StockCondition', 'CODISAP', 'UnitOfMeasure', 'Fecha', 'CODIDEPO', 'ExpiryDate', 'SalesArea', 'SOHValue'];
   

    cabeceras(numero, codigo_Sap, tipo){
        if(numero == 1){
            this.opcion=1;
            this.tipo = 'BPAID_VE_'+codigo_Sap+'_'+tipo.toUpperCase()+'01_'+this.DAY;
            this.campos = this.campo_cliente;
            this.cabecera = ['NESTLE_DMS', tipo+'01', 'NAMSVEBPAID_'+this.faltaCero(String(codigo_Sap)),''+String(this.DAY)+' ','BlankSpace',];
        }
        if(numero == 2){
            this.opcion=2;
            this.tipo = 'BPAID_VE_'+codigo_Sap+'_SO_'+tipo.toUpperCase()+'_'+this.DAY;
            this.campos = this.campo_facturas;
            this.cabecera = ['NESTLE_DMS', tipo+'_SO', 'NAMSVEBPAID_'+this.faltaCero(String(codigo_Sap)),''+String(this.DAY)+' ','BlankSpace',];
        }
        if(numero == 3){
            this.opcion=3;
            this.tipo = 'BPAID_VE_'+codigo_Sap+'_'+tipo.toUpperCase()+'_'+this.DAY;
            this.campos = this.campo_producto;
            this.cabecera = ['NESTLE_DMS', tipo , 'NAMSVEBPAID_'+this.faltaCero(String(codigo_Sap)),''+String(this.DAY)+' ','BlankSpace',];
        }
    }

    downloadFile(data, filename='data') {
        let csvData = this.ConvertToCSV(data, this.campos);
        //console.log(csvData)
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", this.tipo + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    
    ConvertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = this.cabecera;
         
        /*for (let index in headerList) {
            row += headerList[index] + ',';
        }*/

        row = row.slice(0, -1);
        str += row + '\r\n';
        

	        for (let i = 0; i < array.length; i++) {
	          let line=' ';
	          for (let index in headerList) {
	            let head = headerList[index];

	            if(array[i][head] == undefined){
	                line +=' '+';';
	            }
	            
	            if(array[i][head] != undefined){
	                if(headerList[index] == 'CODIGO_DE_RUTA_ASIGNADA' || headerList[index] == 'CODIGO_DE_CLIENTE' || headerList[index] == 'TELEFONO'){
	                    if(headerList[index] == 'CODIGO_DE_RUTA_ASIGNADA'){
	                        line += 'R'+ array[i][head]+';';
	                    }
	                    if(headerList[index] == 'CODIGO_DE_CLIENTE' || headerList[index] == 'NRO_DOCUMENTO'){
	                        line += this.faltaCero(String(array[i][head])) + ';';
	                    }

	                    if(headerList[index] == 'CODIGO_DE_CLIENTE' || headerList[index] == 'NRO_DOCUMENTO'){
	                        line += this.faltaCero(String(array[i][head])) + ';';
	                    }
	                    if(headerList[index] == 'TELEFONO'){
	                    	const repla = '-';
	                    	let telef = array[i][head].replace(repla,'');

	                        line += telef+';';
	                        //line = line.slice(0, -1);
	            			//str += line + '\r\n';
	            			//line=' ';
	                    }
	                }
	                else{
	                    line += array[i][head] + ';';
	                }
	                
	            }
	            
	        }
	            line = line.slice(0, -1);
	            str += line + '\r\n';
	        }
        return str;
    }

    faltaCero(codigo: String){
        if((10 - codigo.length)>0){
            let faltaCero = 10 - codigo.length;
            let cero = '0000000000';

            let resultado = cero.substring(0,faltaCero) + codigo;

            return resultado;
        }
        return codigo;
    }
}