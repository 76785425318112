import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActFacturaService } from 'src/app/core/services/act-factura.service';
import { Init } from 'src/app/core/interfaces/init';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { Ajax } from 'src/app/core/utils/ajax';
import { session } from 'src/app/core/constantes/sessiones';
import * as moment from 'moment';
import { UrListService } from 'src/app/core/url/url.service';
import { DataVelService } from 'src/app/vel/synchronization/data-vel.service';
import Swal from 'sweetalert2';
import { DatatempService } from '../../datatemp/datatemp.service';

@Component({
  selector: 'app-refresh-invoice',
  templateUrl: './refresh-invoice.component.html',
  styleUrls: ['./refresh-invoice.component.scss'],
})
export class RefreshInvoiceComponent implements OnInit, AfterViewInit {

  loading: boolean = false;
  LETRA: string = '';
  NUMERO: number | string = 0;
  NUMECONT: number | string = 0;
  online: boolean = false;
  emit: boolean;
  arrayFacturas = [];
  loadingScreen = Swal.mixin({
    title: 'Verificando datos...',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
  });
  data: any = {
    LETRA: '',
    NUMERO: '',
    NUMECONT: '',
  };

  constructor(public ActFacturaService: ActFacturaService,
    public auth: AuthService, private listUrl: UrListService,
    public dataVs: DataVelService, public DatatempService: DatatempService) { 

  }

  ngAfterViewInit() {
    this.loading = true;
    this.emit = true;
    this.online = this.auth.offonline;
    this.getLastInvoiceNumber();
  }

  ngOnInit() {}

  async refresh() {
    if (!this.searchForNumber()) {
      this.data['ULTIFECH'] = moment().format('YYYY-MM-DD');
      this.data['LETRA'] = this.LETRA;
      this.data['NUMERO'] = Number(this.NUMERO);
      this.data['RUTA'] = this.auth.user.id_ruta;
      this.data['NUMECONT'] = Number(this.NUMECONT);
      if (this.online) {
        this.loadingScreen.fire();
        await this.DatatempService.add_data(this.data, 'act_factura', true);
        this.loadingScreen.close();
      } else {
        this.dataVs.act_factura = this.data;
      }
      if (this.ActFacturaService.goToCatalog) {
        this.ActFacturaService.camps_hasChanged.emit(true);
      }
      this.emit = false;
      this.closeModal();

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El número de factura ya está registrado!',
      });
    }
  }

  pad(): string {
    let string = this.LETRA;
    let mat = 8 - Number(this.NUMERO.toString().length);
    for (let i = 0; i < mat; i++) {
      string = string + '0';
    }
    string = string + this.NUMERO;
    return string;
  }

  searchForNumber(): boolean {
    let pad = this.pad();
    if (this.arrayFacturas != null && typeof this.arrayFacturas != undefined) {
      if (this.arrayFacturas.filter(x => x.NUMEROFACTURA == pad && x.NUMEROCONTROL == this.NUMECONT).length > 0) {
        return true;
      }
    }
    return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setData(type: number, data: any): void {
    if (type == 1) {
      this.LETRA = data;

    } else if (type == 2) {
      this.NUMERO = data;

    } else if (type == 3) {
      this.NUMECONT = data;
    }
  }

  closeModal(): void {
    this.ActFacturaService.closeModal.hide();
    if (this.emit) {
      this.ActFacturaService.closedModal.emit();
    }
  }

  ajaxCall() {
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('obtener_estado_factura'),
      auth: this.auth
    };
    const configAjax: Config = {
      visible: false,
      params: { name: session.act_factura, find: 'entities' }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if(resp.entities.length == 0) {
        this.data = {
          TIPO: 'FA',
          DESCDOCU: 'FACTURA',
          LETRA: '',
          NUMERO: 0,
          ULTIFECH: '',
          NUMECONT: 0,
          RUTA: this.auth.user.id_ruta
        };
      } else {
        this.data = resp.entities[0];
      }
      this.NUMECONT = this.data['NUMECONT'];
      this.LETRA = this.data['LETRA'];
      this.NUMERO = this.data['NUMERO'];
      this.loading = false;
    }).catch(error => {
      this.data = {
        TIPO: 'FA',
        DESCDOCU: 'FACTURA',
        LETRA: '',
        NUMERO: 0,
        ULTIFECH: '',
        NUMECONT: 0,
        RUTA: this.auth.user.id_ruta
      };
      this.loading = false;
    });
  }

  async getLastInvoiceNumber() {
    let act;
    if (this.online) { 
      await this.DatatempService.get_data(['act_factura', 'facturas']).then(resp => {
        act = resp['act_factura'][0];
        if(resp['facturas'] != undefined) {
          this.arrayFacturas = resp['facturas'];
        }
      });
    } else {
      act = this.dataVs.act_factura;
      if (this.dataVs.facturas != null && this.dataVs.facturas != undefined) {
        this.arrayFacturas = this.dataVs.facturas;
      }
    }
    if (act != null && act != undefined) {
      
      this.data = act;
      this.LETRA = this.data['LETRA'];
      this.NUMERO = this.data['NUMERO'];
      this.NUMECONT = this.data['NUMECONT'];
      this.loading = false;
    } else {
      await this.ajaxCall();
    }
  }
}
