import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceDuplicatedService {

  modal: any;
  parentComponent: any;
  closeModal: any;
  last_insert: string;
  listInvoices = Array();
  NUMEROFACTURA: string;
  openModal_emitter: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
