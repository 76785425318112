import { EventEmitter, Injectable } from '@angular/core';
import { session, session_error } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Ajax } from 'src/app/core/utils/ajax';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config, Config2 } from 'src/app/core/interfaces/config';
import { rutasUrl } from 'src/app/core/constantes/rutas';
import Push from "push.js";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Confir} from 'src/app/core/viewloader/view-loader';
import Ajax2 from '../utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {
  pestana= 'notificacion';
  modo_grupo: String;
  grupo_id_edit: any;
  Modal_Grup: any;
  close_Modal_grup: any;
  mover_campana = false;
  notificaciones= [];
  all_notificacion: any;
  renderNoti = [];
  closeModal: any;
  usuarios_sucursal: any;
  grupos = [];
  Modal: any;
  contador= 0;
  fecha_actualizar=null;
  carga = true;
  leido = false;
  alert = new Confir();

  send_error: boolean = true;

  even_grupos: EventEmitter<any> = new EventEmitter();
  even_nuevos_msj: EventEmitter<any> = new EventEmitter(); 
  even_bandeja_notificacion: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router,
    public auth: AuthService,
    private listUrl: UrListService,
    public LocalNotifications: LocalNotifications,
    ){ 

    this.even_nuevos_msj.subscribe(resp => {
      this.nuevas_notificaciones();
    });

    this.even_bandeja_notificacion.subscribe(resp => {
      this.notificacion_leidas();
      this.irBandejadeEntrada();
    });
	}

  number_pendiente(){
    this.actualizacion();
    this.actualizacion_de_peticion();

    if (Cache.getLocal(session.new_notification)) {
    	this.auth.notificaciones = Cache.getLocal(session.new_notification, true).new_notification;
      return Cache.getLocal(session.new_notification, true).new;
    } else {
        this.mover_campana = false;
        this.auth.notificaciones = [];
    }
    return this.mover_campana;
  }


  actualizacion(){
    if(Cache.getLocal(session_error.respaldo_vel) && this.send_error == true){
      if(window.navigator.onLine){
        this.send_error= false;
        this.fecha_actualizar =  moment(Cache.getLocal(session.init_date, true).strdate).subtract(1,'minutes').format('YYYY-MM-DD HH:mm');
      }
    }
    if(this.fecha_actualizar == null){
      if(Cache.getLocal(session.init_date)){
        this.fecha_actualizar =  moment(Cache.getLocal(session.init_date, true).strdate).add(1,'minutes').format('YYYY-MM-DD HH:mm');
      }
    }
  }

  diferencia_fecha(fecha){
    let fecha1= undefined;
    if(moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'days') == 0){
        if(moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'minutes') <= 0){
            return ("Hace: 1 min");
        }else if(moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'minutes') > 0 && moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'minutes') < 60){
          fecha1 = moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'minutes');
          return ("Hace: "+fecha1 + " min");
        }else{
          fecha1 = moment(Cache.getLocal(session.init_date, true).strdate).diff(fecha,'hours');
          return ("Hace: "+fecha1 + " hr");
        }
    }else{
      return (moment(fecha).format('DD-MM-YYYY'));
    }
  }

  mensajeCorto(cadena: String){
    let cadenita = cadena;
    if(cadenita.length > 18){
      return(cadenita.substr(0,18)+"...");
    }else{
      return(cadenita);
    }
  }

  remitenteCorto(cadena: String){
    let cadenita = cadena;
    if(cadenita.length > 15){
      return(" "+cadenita.substr(0,15)+"...");
    }else{
      return(" "+cadenita);
    }
  }

  notificacion_leidas(){
    this.mover_campana= false;
    if (Cache.getLocal(session.new_notification)) {
      Cache.setLocal(session.new_notification,{'new_notification': Cache.getLocal(session.new_notification, true).new_notification, 'new': false});
    }
    if(window.navigator.onLine){
      if(this.renderNoti!= undefined && this.renderNoti.length>0 && this.leido==false){
        const initAjax: Init = {
          method: 'post',
          url: this.listUrl.url('notificacion_leidas'),
          auth: this.auth,
          body: {
            'notificaciones': this.renderNoti,
            'id_user': this.auth.user.idusuario    
            }
          };
        const configAjax: Config2 = {
          alerts:false
        };
        const ajax = new Ajax2(initAjax, configAjax);
        ajax.call().then(respo => {
          if(respo.success){
             //console.log(respo);
             this.leido = true;
        }
        }).catch(error => {
           console.log(error);
         });

      } 
    }
  }

  nuevas_notificaciones(){

    this.carga = false;
    this.notificaciones= [];
    this.renderNoti = [];
    let diagnost_error = [];

    Push.clear();
    if(Cache.getLocal(session_error.respaldo_vel)){
      diagnost_error = Cache.getLocal(session_error.respaldo_vel, true);
    }

    if(window.navigator.onLine){
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('nuevas_notificaciones'),
        auth: this.auth,
        body: {
          'usuario_id': this.auth.user.idusuario,
          'login': false,
          'error_vel': diagnost_error
        }
      };
      const configAjax: Config2 = {
        alerts: false,
        time_update: 120000,
        retry: false
      };
      const ajax = new Ajax2(initAjax, configAjax);
        ajax.call().then(respo => {
        if(respo.success){
          Cache.removeLocalItem(session_error.respaldo_vel);
        	Cache.setLocal(session.new_notification,{'new_notification': respo.entities, 'new': respo.new});
          this.renderNoti = respo.entities;
          this.auth.notificaciones = respo.entities;
          this.carga = false;
          this.leido = false;
          this.send_error = true;
          for(let i=0; i < this.renderNoti.length;i++){
            if(i==0){
              this.mover_campana= true;
            }
            if(i<9){
              this.notificaciones.push(this.renderNoti[i]);
            }
          }

          if(respo.entities.length>1){
            this.notificacion_push(268,respo.entities.length+" Nuevos mensajes","Estimado "+this.auth.user.nombre_user+", usted tiene "+respo.entities.length+" nuevos mensajes en la bandeja de entrada de notificaciones del sistema 360");
          }else{
            this.notificacion_push(respo.entities[0].notificacion_id,respo.entities[0].remitente,respo.entities[0].notificacion);
          }
          //console.log(respo);
        }}).catch(error => {
          this.mover_campana= false;
          Cache.setLocal(session.new_notification,{'new_notification': [], 'new': false});
       });
    }
  }

  actualizacion_de_peticion(){
    if(Cache.getLocal(session.init_date)){
      if(moment(Cache.getLocal(session.init_date, true).strdate).diff(this.fecha_actualizar,'minutes') > 0){
        this.nuevas_notificaciones();
        this.fecha_actualizar = moment(Cache.getLocal(session.init_date, true).strdate).add(1,'minutes').format('YYYY-MM-DD HH:mm');
      }
    }
    return '';
  }

  notificacion_push(id,titulo,texto){
    if (this.auth.platform.is('android') && !this.auth.platform.is('mobileweb')) {
      this.notificaciones_push_android(id,titulo,texto);
    }else{
      this.notificaciones_push_web(titulo,texto);
    }
  }

  notificaciones_push_android(id,titulo,texto){
    let evento = this.even_bandeja_notificacion;
    this.LocalNotifications.hasPermission().then(granted => { // requestPermission
      if (granted) {
        this.LocalNotifications.schedule({
          id: id,
          title: titulo,
          text: texto,
        });

        // Agregar un escuchador para el evento de clic en la notificación
        this.LocalNotifications.on('click').subscribe(notification => {
          evento.emit();
        });
      }else{
        this.LocalNotifications.requestPermission().then(granted => { 
          if (granted) {
            this.notificaciones_push_android(id,titulo,texto);
          }else{
            /*this.alert.confir('', `¿Desea activar las notificaciones?`, 'question').then((result) => {
              
            });*/
          }
        });
      }
    });
  }

  notificaciones_push_web(titulo,texto){
    try{
      let evento = this.even_bandeja_notificacion;
      Push.Permission.request(
        function () {
          Push.create(titulo, {
            body: texto,
            icon: "assets/icon/favicon.png",
            requireInteraction: true,
            onClick: function () {
              evento.emit();
              window.focus();
              this.close();
            },
            
          });
          
        },
        function () {
          console.log("No se pueden mostrar las notificaciones porque no tienes el permiso");
        }
      );
    }catch(e){
      console.log(e);
    }
  }

  irBandejadeEntrada() {     
    this.router.navigate([rutasUrl.notificaciones]);
  }


  getTodos_grupos(alerts=true, titles:any={}):Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('todos_grupo'),
        auth: this.auth,
        body: {}
      };
      const configAjax: Config2 = {
        alerts,
        titles,
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.grupos = resp.entities;
        this.even_grupos.emit({grupos: resp.entities});
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

}
