import { UrListService } from './url.service';

export class Url {

    elemet: string;

    constructor(elemet: string, public urlList: UrListService) {
      this.elemet = elemet;
    }

    url(): string {
        return this.elemet;
    }

}

export class UrlBase {

    url_base: string;
    urlList = {
        activeWaiter: false,
    };

    constructor(url: string) { this.url_base = url; }

    url() {
        return this.url_base;
    }
}
