import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  closeModal: any;
  objFeed: any;
  updateLeido: boolean = false;
  isNewCategory: boolean = true;
  idCategory: string = '';
  objCat: any;
  even_remove: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
