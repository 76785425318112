import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ActivationEnd,
  Router
} from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { TitlesService } from './core/services/titles.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  loading = false;
  nobackto = true; //True para registrar, false para ignorar

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, 
    public route: Router,
    public auth: AuthService,
    public titles: TitlesService,
  ) {
    this.initializeApp();
    this.titles.even_toback.subscribe( (res)=>{
      if(res.url){
        this.route.navigateByUrl(res.url);
      }
    });
    this.route.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof ActivationEnd: {
          let even = event as ActivationEnd;
          let titl = even.snapshot.data.titlebar;
          let aside = even.snapshot.data.hideaside;
          let nobackto = even.snapshot.data.nobackto;
          let selectAside = even.snapshot.data.selectAside;
          if(even.snapshot.children.length == 0){
            if(titl){
              this.titles.titlebar = even.snapshot.data.titlebar;
              this.titles.inselectruta = even.snapshot.data.hasOwnProperty('inselectruta');
            }else{
              this.titles.resetTitlebar();
            }
            if(aside){
              this.titles.hide_aside();
            }else{
              this.titles.show_aside();
            }
            if (nobackto != undefined) {
              this.nobackto = nobackto;
            }else{
              this.nobackto = true;
            }
            if(selectAside){
              this.titles.setselectAside(selectAside);
            }
          }
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          if (this.nobackto) {
            this.titles.add_backtogo= this.route.url.toString();
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('android') && this.platform.is('mobileweb') == false) {
        this.statusBar.styleDefault();
        this.statusBar.overlaysWebView(false);
        this.splashScreen.hide();
      }
    });
  }

}
