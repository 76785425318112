import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Cache } from '../utils/cache';
import { session_p, session } from '../constantes/sessiones';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { InitDateService } from 'src/app/core/init-date/init-date.service';
import Swal from 'sweetalert2';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  username: string | null = null;
  password: string | null = null;
  remember = false;
  return = '';
  error: any;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordfc = new FormControl('', [
    Validators.required,
  ]);

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private statusBar: StatusBar,
    private platform: Platform,
    private InitDateService: InitDateService
  ) {

  }

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('android') && this.platform.is('mobileweb') == false) {
        this.statusBar.backgroundColorByHexString('#212121');
      }
    });
    if (Cache.getLocal(session_p.rememberUser)) {
      this.remember = Cache.getLocal(session_p.rememberUser);
      this.username = Cache.getLocal(session_p.usernameSession);
    }
    this.route.queryParams.subscribe(params => {
      if (params.return== undefined || params.return == '/notfound') {
        this.return = '/';
      }else{
        this.return = params.return;
      }
    });
  }

  async onSubmit() {
    if (this.username && this.password && this.username !== '' && this.password !== '') {
      try {
        const login = await this.auth.login(this.password, this.username, this.remember);
        if (login) {
          let usr = Cache.getLocal(session.user, true);
          this.InitDateService.getDate(usr.idsucursal, usr.zona_horaria_id);
          this.router.navigateByUrl(this.return);
        }
      } catch (error) {
      }
    }else{
      Swal.mixin({
        icon: 'error',
        title: 'Error al validar datos',
        text: 'El correo electrónico y la contraseña no pueden estar vacíos',
      }).fire();
    }
  }

}
