import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonMoreComponent } from './button-more.component';




@NgModule({
  declarations: [
    ButtonMoreComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ButtonMoreComponent
  ]
})
export class ButtonMoreModule { }
