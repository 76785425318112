import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Cache } from 'src/app/core/utils/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { rutasUrl } from 'src/app/core/constantes/rutas';
import { ConfirmTobackService } from './confirm-toback.service';
import { constant } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TitlesService{

  even_sync_appsmodulos: EventEmitter<any> = new EventEmitter();
  even_toback: EventEmitter<any> = new EventEmitter();

  _titlebar: string;
  _loadingbar: boolean;
  inselectruta = false;
  _selectedtabmenu = 0;
  _selected_aside_menu = 0;
  _loadingAlert: boolean = false;//pendiente

  hideaside = 'hideaside';
  stop_loadingbar = false;//Pasa a True cuando _loadingbar se asigna False, y asi entrar en el interval para detenerlo
  pass_inter_loadingbar = true;//Condicion para generar el interval y evitar que haya mas de 1 interval a la vez.
  interval_loadingbar:any;//Guarda el interval creado. El interval estara verificando si _loadingbar paso de True a False, de ser asi lo asigna false y se autodestruye
  tobackconfirm:  boolean = true;//false para activar

  constructor(
    private titleS: Title,
    public toback: ConfirmTobackService
  ){
    this.resetTitlebar();
  }

  resetTitlebar(){
    this.titlebar = 'Bonaventura';
  }

  setTitleRoute(adata: any){
    adata.subscribe( data =>{
      if(data.hasOwnProperty('titlebar')){
        this.titlebar = data.titlebar;
      }else{
        this.resetTitlebar();
      }
    });
  }

  setTitle(title: string){
    this.title = title;
  }

  set titlebar(title){
    this._titlebar = title;
  }

  get titlebar(): string{
    return this._titlebar;
  }

  get title(): string {
    return this.titleS.getTitle();
  }

  set title(title){
    this.titleS.setTitle(title);
  }

  set tabmenu(tab:number){
    this._selectedtabmenu = tab;
  }

  get tabmenu(){
    return this._selectedtabmenu;
  }

  set loadingbar(load:boolean){
    if(load && this.pass_inter_loadingbar){
      this.pass_inter_loadingbar = false;
      this.interval_loadingbar = setInterval(()=>{
        if(!this._loadingbar || this.stop_loadingbar){
          this.stop_loadingbar = false;
          this.loadingbar = false;
          this.pass_inter_loadingbar = true;
          clearInterval(this.interval_loadingbar);
          this.interval_loadingbar = null;
        }
      },800);
    }else if(!load){
      this.stop_loadingbar = true;
    }
    this._loadingbar = load;
  }

  get loadingbar(){
    return this._loadingbar;
  }

  set add_backtogo(url:string){//Añade una url
    let urls = [];
    if(url != rutasUrl.notfound){// no guardo cuando entra aqui
      if(url == rutasUrl.hometdp || url == rutasUrl.homen360){ // reinicio si entra aqui
        Cache.setSession(session.backtogo, [url]);
      }else{
        if (this.backtogo != undefined) {
          urls = [...this.backtogo];
          let ind = urls.findIndex((ee) => ee == url);
          if(ind>0){// si se repite una url entonces reinicio hasta ese punto
            urls = urls.slice(0,ind+1);
            Cache.setSession(session.backtogo, urls);
          }else if(urls[urls.length-1] != url){
            urls.push(url);
            Cache.setSession(session.backtogo, urls);
          }
        }else{
          Cache.setSession(session.backtogo, [url]);
        }
      }
    }
  }

  set add_all_backtogo(urls:any){//Añade una lista de url en array, sin comprobacion.
    if (Array.isArray(urls)) {
      Cache.setSession(session.backtogo, urls);
    }
  }

  get getbacktogo():any{//Al darle al boton de regresar, Devuelve la url a regresar y actualiza la lista
    if(this.tobackconfirm){
      let urls = [...this.backtogo];//lista
      urls.pop();//elimino la url actual donde se dio a retroceder
      let url = urls.pop();// necesario para no duplicar urls, regresa la url a la que se esta devolviendo
      this.add_all_backtogo = urls;
      return url;
    }
    return false;
  }

  get backtogo():any{//Devuelve la lista de urls
    return Cache.getSession(session.backtogo, true);
  }

  gotoback(guard=false){
    if(this.toback.activeToback){
      if(guard){
        this.tobackconfirm =  true;
        this.toback.activeToback = false;
        let urll = this.gotoback();
        this.even_toback.emit({url: urll});
      }else{
        this.toback.confirm('Aun hay datos por guardar, ¿Salir y borrar los datos?').subscribe( conf => {
          if(conf){
            this.tobackconfirm =  true;
            this.toback.activeToback = false;
            let urll = this.gotoback();
            this.even_toback.emit({url: urll});
          }
        });
      }
    }else{
      let urls = [...this.backtogo];
      if(urls.length>1){
        let tback = this.getbacktogo;
        if(tback){
           return tback;
        }
      }
    }
    return false;
  }

  hide_aside(){
    this.hideaside = 'hideaside';
  }

  show_aside(){
    this.hideaside = ' ';
  }

  setselectAside(ind = 0){// Para selecionar la app en donde se encunetra el usuario (iluminar el icono en el aside)
    this._selected_aside_menu = ind;
  }

  get selected_aside_menu():any{
    return this._selected_aside_menu;
  }

}
