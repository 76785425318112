import { Injectable } from '@angular/core';

declare let gtag:Function;

@Injectable({ providedIn: 'root' })

export class GoogleAnalyticsSevice {

  private static send_data: boolean = false; //determina si se va a usar el google analytics o no
  private static google_Analytics_id = "G-CVHRG2ZGM1";

  constructor() {}
    
  get_google_Analytics_id(): string{
    // retorna el id que posee la propiedad
      return GoogleAnalyticsSevice.google_Analytics_id;
  }

  eventEmitter( 
                  eventName: string, 
                  eventCategory: string, 
                  eventAction: string, 
                  eventLabel: string | null = null,  
                  eventValue: number | null = null
              ){ 
    /**
     * Envia los eventos al google analytics
     */
    if(GoogleAnalyticsSevice.send_data){
                gtag('event', eventName, { 
                        eventCategory: eventCategory, 
                        eventLabel: eventLabel, 
                        eventAction: eventAction, 
                        eventValue: eventValue
                    })
     }
  }
    
  
  page_config(title : string, url : string){
    /**
     * Seguimiento de las visitas de las páginas
     */ 
     
    if(GoogleAnalyticsSevice.send_data){
      gtag( 
            'config', 
            GoogleAnalyticsSevice.google_Analytics_id,  
            { 
              page_title: title,
              page_path: url,
            }
          )
        }
    
  }

  static addScriptGoogleCode(){
    /**
     * Esta función se declara el main.ts y lo que hace es que añade el código script la index.hmtl
     * para poder usar el google analytics
     */
    if(GoogleAnalyticsSevice.send_data){
        
      let script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.google_Analytics_id}');`;
      document.head.appendChild(script);

      let scriptTwo = document.createElement('script');
      scriptTwo.innerHTML = `window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());`;
      document.head.appendChild(scriptTwo);
    }
  }
}