import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { rutasUrl as rurl } from 'src/app/core/constantes/rutas';

@Injectable({
  providedIn: 'root'
})
export class OffonlineGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if( this.auth.checkOffOnLine() ){ 
        return true;
      }else if(!this.auth.modeoffonline){
        this.router.navigate([rurl.elegir_modo],{
          queryParams: {
            return: state.url
          }
        });
        return false;
      }else if(!this.auth.cargadaiDB){
        this.router.navigate([rurl.espera_indb],{
          queryParams: {
            return: state.url
          }
        });
        return false;
      }
  }
  
}
