import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { User } from '../interfaces/user';
import { Cache } from '../utils/cache';
import { session, session_p } from '../constantes/sessiones';
import { Init } from '../interfaces/init';
import { Config2 } from '../interfaces/config';
import { Token } from '../token/token';
import { ListUrl } from '../constantes/request';
import { Router } from '@angular/router';
import { UrlBase } from '../url/url';
import { Search } from '../utils/search';
import { Modulo } from '../interfaces/modulos';
import { DataVelService } from 'src/app/vel/synchronization/data-vel.service';
import { Platform } from '@ionic/angular';
import Swal from 'sweetalert2';
import { Confir } from '../viewloader/view-loader';
import { var_alerts } from 'src/app/core/constantes/var_alerts';
import Ajax2 from '../utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: User | null;
  token: Token | null;
  timeout = 15000;
  intent = 1;
  intentmax = 100;
  intentAument = 5;
  seg_sum = 5000;
  public http: HttpClient | HTTP;
  db_cache: boolean = false;
  notificaciones = [];
  type_user: any = 1; // Variable para determinar si el tipo de usuario es de 360 o vendedor, 1 = vendedor y 2 = vendedor
  alertaLoading: any;
  confirmodalupdate = false;

  offonline = true; // Determina si esta activo el online o el offline. True para Online, False para Offline
  modeoffonline = false; // Determina si ya escogio el modo 
  activo_offonline = true; // Determina si la opcion de escoger modo ha sido habilitada por la configuracion
  cargadaiDB = false; // Determina si la bd se cargo correctamente
  socket: any;
  statusSocket: boolean;

  constructor(
    public platform: Platform, 
    public cliangular: HttpClient, 
    public clianative: HTTP, 
    public router: Router, 
    public dataVs: DataVelService
  ) {

    if (this.platform.is('android') && this.platform.is('mobileweb') == false) {
      this.http = this.clianative;
    } else {
      this.http = this.cliangular;
    }

    if ( Cache.getLocal(session.modooffon) ) {
      let modoos = Cache.getLocal(session.modooffon, true);
      this.offonline = modoos.offonline;
      this.modeoffonline = modoos.modeoffonline;
      this.activo_offonline = modoos.activo_offonline;
      this.cargadaiDB = modoos.cargadaiDB;
    }else{
      this.resetModos();
    }
    
    try {
      if (Cache.getLocal(session.user)) {
        this.user = Cache.getLocal(session.user, true) as User;
      } else {
        this.user = null;
      }
      if (Cache.getLocal(session.tokenAc)) {
        this.token = new Token(Cache.getLocal(session.tokenAc));
      } else {
        this.token = null;
      }
    } catch (error) {
      this.logout();
    }
    this.alertaLoading = Swal.mixin({
      title: 'Cerrando sesión',
      html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  }

  get_token(): string | null {
    if (this.token) {
      return this.token.token;
    } else {
      return null;
    }
  }

  getHeaders(): HttpHeaders | null {
    let headers = new HttpHeaders();
    if (this.isLogin() && this.token.token !== '') {
      headers = headers.set('Authorization', this.token.token);
    } else {
      return null
    }
    return headers;
  }

  set user(us: User) {
    Cache.setLocal(session.user, us);
    this._user = us;
  }

  get user(): User {
    return this._user;
  }

  login(pass: string, user: string, remember: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: new UrlBase(ListUrl.login_url),
        auth: this,
        body: {
          email: user,
          password: pass
        }
      };
      const configAjax: Config2 = {
        authenticate: false,
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then((reponse) => {
        if (reponse.success) {
          this.token = new Token(reponse.access);
          reponse.user.vars_limits = var_alerts;
          this.user = reponse.user as User;
          if (this.db_cache == false) {
            // Cache.create_db().then( async (resp)=>{
            //   let aqui = await Cache.exist_table();
            //   if (aqui) {
            //     this.db_cache = true;
                if(window.navigator.onLine){
                  this.nuevas_notificaciones(true,this.user.idusuario);
                }
                
                if (remember) {
                  Cache.setLocal(session_p.rememberUser, remember);
                  Cache.setLocal(session_p.usernameSession, user);
                } else {
                  Cache.removeLocalItem(session_p.rememberUser);
                  Cache.removeLocalItem(session_p.usernameSession);
                }
                resolve(this.user);
              // }
            // }).catch(err => {
            //   console.log(err);
            //   this.nuevas_notificaciones(true,this.user.idusuario);
            //   if (remember) {
            //     Cache.setLocal(session_p.rememberUser, remember);
            //     Cache.setLocal(session_p.usernameSession, user);
            //   } else {
            //     Cache.removeLocalItem(session_p.rememberUser);
            //     Cache.removeLocalItem(session_p.usernameSession);
            //   }
            //   resolve(this.user);
            // });
          }
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  check_perm(perm: number): boolean {
    if (typeof this.user.modulos !== 'undefined' && this.user.modulos && this.user.modulos.length) {
      let resp = Search.filter(this.user.modulos, (x: Modulo, e)=> {
        return Search.igual(parseInt(x.idmodulo), parseInt(e));
      }, perm);
      if (resp.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  check_action(perm: string): boolean {
    if (typeof this.user !== 'undefined' && this.user.perms && this.user.perms.length) {
      return this.user.perms.includes(perm);
    } else {
      return false;
    }
  }

  logout_process() {
    this.token = null;
    this._user = null;
    this.db_cache = false;
    this.notificaciones = [];
    this.resetModos();
    for (const key in session) {
      if (Object.prototype.hasOwnProperty.call(session, key)) {
        Cache.removeItem(session[key]);
      }
    }
  }

  token_expire() {
    this.logout();
    //this.router.navigate(['/expired']);
  }

  outdated_p() {
    this.logout();
    //this.router.navigate(['/expired']);
  }

  outdated_s() {
    const confirmar = new Confir();
    if (this.confirmodalupdate == false) {
      this.confirmodalupdate = true;
      confirmar.confir('Tu aplicacion esta desactualizada por favor cerrar session y ingresar nuevamente para actualizar', 'Aplicacion desactualizada').then(resp => {
        this.logout();
        this.confirmodalupdate = false;
      });
    }
    //this.router.navigate(['/expired']);
  }
   
  logout(paramsReturn: string | boolean = false){
    if(this.user){
      this.alertaLoading.fire();
      this.dataVs.removeData();
      this.logout_process();
    }
    if (paramsReturn) {
      // redirect con parametos
      this.router.navigate(['/login'], {
        queryParams: {
          return: paramsReturn
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
    setTimeout(() => {
      this.alertaLoading.close();
    }, 1000);
    setTimeout(() => {Cache.delete_db().then(resp => {}).catch(err => {console.log(err);});}, 3000);
  }

  isLogin(): boolean {
    if (this.user && this.token) {
      if (this.token.validateAllToken()) {
        return true;
      } else {
        this.token_expire();
        return false;
      }
    } else {
      return false;
    }
  }

  set ruta(rt: string) {
    let user = Object.assign(this.user);
    user.id_ruta = rt;
    this.user = user;
  }

  isRuta(): boolean{
    return this.user.id_ruta != '';
  }

  isVel(): boolean{
    let isvel = false;
    if (this.user && this.user.modulos && this.user.modulos.length > 0){
      let vel = this.user.modulos.reduce((arr: any,modu: any) => {
        if (modu.idmodulo == 58){//VEL idmodulo 58
          isvel = true;
        }
        return isvel
      }, []);
      return vel;
    }else {
      return false;
    }
  }

  isOffOn(){
    if(this.activo_offonline){
      if (this.modeoffonline) {
        return this.offonline;
      }else{
        return null;
      }
    }else{
      this.offonline = true;
      this.modeoffonline = true;
      return this.offonline;
    }
  }

  activaModo(modo:boolean){
    this.offonline = modo;
    this.modeoffonline = true;
    this.cargadaiDB = modo;
    this.guardaModos();
  }

  checkOffOnLine(){
    if( this.activo_offonline ){// Si esta activo, entonces debe escoger modo
      if( this.modeoffonline ){ //Se comprueba si ya escogio el modo
        if( this.offonline ){// se comprueba si el modo escogido es online
          return true;
        }else{ // Si es offline se comprueba si cargo la db
          if (this.cargadaiDB) {
            return true;
          }else{
            return false;
          }
        }
      }else{
        return false;
      }
    }else{// Si esta inactivo, se asume modo online
      return true;
    }
  }

  guardaModos(){
    let modoos = {
      offonline: this.offonline,
      modeoffonline: this.modeoffonline,
      activo_offonline: this.activo_offonline,
      cargadaiDB: this.cargadaiDB
    };
    Cache.setLocal(session.modooffon, modoos);
  }

  resetModos(){
    this.offonline = true;
    this.modeoffonline = false;
    this.activo_offonline = true;
    this.cargadaiDB = false;
  }

  async validate_ajax(resp: any) {
    if (typeof resp.error !== 'undefined' && resp.error == '807') {
      this.token_expire();
      throw 'El token expiro';
    }
    if (typeof resp.error !== 'undefined' && resp.error == '700') {
        this.outdated_p();
        throw 'Aplicacion desactualizada';
    }
    if (typeof resp.error !== 'undefined' && resp.error == '701') {
        this.outdated_s();
        throw 'Aplicacion desactualizada';
    }
    if (typeof resp.error !== 'undefined' && resp.error == '702') {
        this.outdated_p();
        throw 'Aplicacion desactualizada';
    }
    return true
  }

  nuevas_notificaciones(visible=true, idusuario){
        const initAjax: Init = {
          method: 'post',
          url: new UrlBase(ListUrl.nuevas_notificaciones),
          auth: this,
          body: {
            'usuario_id': idusuario,
            'login': true
          }
        };
        const configAjax: Config2 = {
            alerts: false,
            time_update: 120000,
            retry: false,
            skip_url: true
        };
        const ajax = new Ajax2(initAjax, configAjax);
        ajax.call().then(respo => {
            if(respo.success){
            	this.notificaciones = respo.entities;
              Cache.setLocal(session.new_notification,{'new_notification': respo.entities, 'new': respo.new});
            }
        }).catch(error => {
          Cache.setLocal(session.new_notification,{'new_notification': [], 'new': false});
        });
    }
}
