import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { TitlesService } from 'src/app/core/services/titles.service';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';
import Swal from 'sweetalert2';
import { DataVelService } from '../synchronization/data-vel.service';
import { SyncService } from '../synchronization/sync.service';
import { rutasUrl } from 'src/app/core/constantes/rutas';
import { SendDataService } from '../synchronization/send-data.service';


@Component({
  selector: 'app-select-ruta',
  templateUrl: './select-ruta.component.html',
  styleUrls: ['./select-ruta.component.scss'],
})
export class SelectRutaComponent implements OnInit {

  rutas: any;
  norutas = true; //Especifica si el usuario posee rutas
  rut= '';
  rutavalida= false;
  return = '';
  buscando = false;

  constructor(
    public auth: AuthService, 
    public dataVs: DataVelService, 
    private router: Router, 
    private route: ActivatedRoute,
    private listUrl: UrListService,
    public titles: TitlesService,
    public sync: SyncService,
    public sendsv: SendDataService
  ){
    this.loadRutas();
  }

  ngOnInit() {
    this.route.queryParams.forEach(pr => this.return = pr.return || rutasUrl.hometdp);
  }

  loadRutas(){
    // cargar rutas desde el back
    this.rutas = this.auth.user.rutas;
    if(this.rutas == ''){
      this.buscando = true;
      this.get_user_rutas();
    }else{
      this.get_user_rutas();
    }
  }

  validaRuta(){
    if(this.rut == ''){
      this.alerta("Debe escoger una ruta");
    }else{
      this.auth.user.rutas.forEach(el => {
        if(el.id_ruta == this.rut){
          this.rutavalida = true;
        }
      });
      if(this.rutavalida){
        this.sendsv.deleteDataCache((call)=> {
          if (call) {
            this.auth.ruta = this.rut;
            this.dataVs.even_cambio_ruta.emit({});
            this.sync.startSync('all', true);
            this.router.navigate([this.return]);
          }
        });
      }else{
        this.alerta("Ruta incorrecta");
      }
    }
  }

  alerta(msj){
    Swal.mixin({
      icon: 'error',
      title: msj,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Aceptar",
      showCloseButton: true
    }).fire();
  }

  get_user_rutas(){
    const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_user_rutas'),
        auth: this.auth,
        body: {}
    };
    const configAjax: Config = {
        visible: false,
        titles: this.titles,
        force_update: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        if(Array.isArray(respo.salida)){
          let user = Object.assign(this.auth.user);
          user.rutas = respo.salida;
          this.auth.user = user;
          this.rutas = respo.salida;
          if(respo.salida.length == 0){
            this.norutas = false;
          }
        }
      }
      this.buscando = false;
    }).catch(error => {
      this.buscando = false;
      // this.norutas = false;
    });
  }

  getId(){
    return 58;
  }

}
