import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

export interface Actions {
  action: 'paste'| 'click'| 'keyup' | 'key';
  search: string;
  event: any;
}

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputSearchComponent implements OnInit {

  @Output() actions = new EventEmitter<Actions>();
  public search: string;
  @Input() class = 'form-control';
  @Input() name = 'table_search';
  @Input() placeholder = 'Búsqueda';

  constructor() { }

  ngOnInit() {}

  onAction(event: any) { 
    this.actions.emit({action: 'paste', search: this.search, event: event});
  }

}
