import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config2 } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { DataVelService } from './data-vel.service';
import { Cache } from 'src/app/core/utils/cache';
import { DatatempService } from 'src/app/vel/datatemp/datatemp.service';
import { session } from 'src/app/core/constantes/sessiones';
import * as moment from 'moment';
import { InvoiceDuplicatedService } from 'src/app/core/services/invoice-duplicated.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataSendModalComponent } from 'src/app/vel/modals/data-send-modal/data-send-modal.component';
import { EventosService } from 'src/app/core/services/eventos.service';
import { CobranzasVelService } from 'src/app/core/services/cobranzas-vel.service';
import Ajax2 from 'src/app/core/utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {
  sendingData = Swal.mixin({
    title: 'Enviando Datos',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
  });

  alertSuccess: SweetAlertOptions = {
    icon: 'success',
    title: 'Datos enviados exitosamente',
    showConfirmButton: false,
    timer: 1500
  };
  wasSent: boolean = false;
  errPedidos = [];
  errFacturas = [];

  //Variables para almacenar los objetos de datatemp del modo online

  arrayPedidos = [];
  arrayFacturas = [];
  arrayAutoventa = [];
  arrayVisitas = [];
  actFactura: any;
  arrayCobranzas: any;
  bsModalRef: BsModalRef;
  duplicatedInvoice = {result: false, obj: '', last_insert: ''};

  //
  respcobra: any = {
    success: true,
    msj: ''
  };

  constructor(
    public auth: AuthService,
    public dataVs: DataVelService, 
    public DatatempService: DatatempService,
    private listUrl: UrListService,
    public cobraSv : CobranzasVelService,
    public modalService: BsModalService,
    public InvoiceDuplicatedService: InvoiceDuplicatedService,
    private EventosService: EventosService,
  ) { }

  deleteDataCache(callback: any = false, tipo='ruta') {
    //Funcion movida del DataVelService hacia este service por dependencia circular.
    if (this.dataVs.poseeDatosPorEnviar(false)) {
      if(tipo == 'elegir_modo'){
        this.dataVs.removeDataCache();
        callback(true);
      }else{
        const config: any = {
          // ignoreBackdropClick: true,
          // keyboard:false,
          initialState: {    
            tipo,
            sendata: this,
          }
        }
        this.bsModalRef = this.modalService.show(DataSendModalComponent, config);
        this.bsModalRef.content.even_send_data.subscribe(resp => {
          this.dataVs.removeDataCache();
          callback(true);
        });
      }
    } else {
      this.dataVs.removeDataCache();
      callback(true);
    }
  }

  async startSendData(nologout=true) {
      this.wasSent = false;
      this.sendingData.fire();
      this.respcobra = {
        success: true,
        msj: ''
      };
      let has_pedido: number = 0;
      let has_cobra: number = 0;
      let has_visit: number = 0;
      let has_autoventa: number = 0;
      let has_invoice: number = 0;
      try {
        if (this.auth.offonline) {  //Condición que controla de donde se tiene que tomar los datos dependiendo del modo para poder enviarlos
          await this.DatatempService.get_data(['pedidos', 'facturas', 'act_factura', 'cobranzas_4_0','visitas','facturas_autoventa']).then(resp => {
            this.arrayFacturas = resp['facturas'];
            this.arrayPedidos = resp['pedidos'];
            this.arrayAutoventa = resp['facturas_autoventa'];
            this.actFactura = resp['act_factura'][0];
            if(resp['visitas'].length){
              this.arrayVisitas = resp['visitas'][0];
              if (Array.isArray(this.arrayVisitas)) {
                has_visit = this.arrayVisitas.filter(x => !x.SENT).length;
              }
            }
            if(resp['cobranzas_4_0'].length){
              this.arrayCobranzas = resp['cobranzas_4_0'][0];
              if (Array.isArray(this.arrayCobranzas.recibos)) {
                has_cobra = this.arrayCobranzas.recibos.filter(x => !x.sent).length;
              }
            }
            if (this.arrayPedidos != undefined) {
              if (Array.isArray(this.arrayPedidos)) {
                has_pedido = this.arrayPedidos.filter(x => !x.SENT).length;
              }
            }
            if (this.arrayFacturas != undefined) {
              if (Array.isArray(this.arrayFacturas)) {
                has_invoice = this.arrayFacturas.filter(x => !x.SENT).length;
              }
            }
            if (this.arrayAutoventa != undefined) {
              if (Array.isArray(this.arrayAutoventa)) {
                has_autoventa = this.arrayAutoventa.filter(x => !x.SENT).length;
              }
            }
          });

        } else {  //Si está en modo offline se toman los datos de la caché en vez del datatemp....
          if (this.dataVs.pedidos != null && this.dataVs.pedidos != undefined) {
            if (Array.isArray(this.dataVs.pedidos)) {
              has_pedido = this.dataVs.pedidos.filter(x => !x.SENT).length;
              this.arrayPedidos = this.dataVs.pedidos;
            }
          }
  
          if (this.dataVs.facturas != null && this.dataVs.facturas != undefined) {
            if (Array.isArray(this.dataVs.facturas)) {
              has_invoice = this.dataVs.facturas.filter(x => !x.SENT).length;
              this.arrayFacturas = this.dataVs.facturas;
              this.actFactura = this.dataVs.act_factura;
            }
          }

          if (this.dataVs.facturas_autoventa != null && this.dataVs.facturas_autoventa != undefined) {
            if (Array.isArray(this.dataVs.facturas_autoventa)) {
              this.arrayAutoventa = this.dataVs.facturas_autoventa;
              has_autoventa = this.arrayAutoventa.filter(x => !x.SENT).length;
            }
          }

          if (this.dataVs.recibos != null && this.dataVs.recibos != undefined) {
            this.arrayCobranzas = this.cobraSv.recibos;
            if (Array.isArray(this.arrayCobranzas.recibos)) {
              has_cobra = this.arrayCobranzas.recibos.filter(x => !x.sent).length;
            }
          }

          if (this.dataVs.visitas != null && this.dataVs.visitas != undefined) {
            if (Array.isArray(this.dataVs.visitas)) {
              this.arrayVisitas = this.dataVs.visitas;
              has_visit = this.dataVs.visitas.length;
              //has_visit = this.dataVs.visitas.filter(x => !x.SENT).length;
            }
          }
          
        }
        if (!has_pedido && !has_cobra && !has_visit && !has_invoice && !has_autoventa) {
          throw 'No existen Pedidos, Facturas, Cobranzas o Visitas pendientes por enviar.';
        }
        
        if (this.arrayPedidos != null && this.arrayPedidos != undefined) {
          if (Array.isArray(this.arrayPedidos)) {
            if (this.arrayPedidos.filter(x => !x.SENT).length > 0) {
              let pedidos = this.arrayPedidos;
              for(let ped of pedidos) {
                if(ped.CODIGLOB == -1) {
                  ped.CODIGLOB = ' ';
                }
              }
              await this.sendPedidos(pedidos);
              pedidos = this.arrayPedidos;
              for (let ped of pedidos) {
                if (this.errPedidos.filter(x => x == ped.NUMEROPEDIDO).length == 0) {
                  ped.SENT = true;
                }
              }
              this.errPedidos = [];
              if (this.auth.offonline) {
                await this.DatatempService.delete_data(['pedidos']);
                let arr = [];
                for (let ped of pedidos) {
                  arr.push(
                  {
                    ruta: this.auth.user.id_ruta,
                    data_type: 'pedidos',
                    modify: false,
                    data_json: ped
                  });
                }
                await this.DatatempService.add_many_data(arr);
              } else {
                /////////////////////////////////////////////////////////////////////////////////
                await this.DatatempService.delete_data(['pedidos']);
                let arr = [];
                for (let ped of pedidos) {
                  arr.push(
                  {
                    ruta: this.auth.user.id_ruta,
                    data_type: 'pedidos',
                    modify: false,
                    data_json: ped
                  });
                }
                await this.DatatempService.add_many_data(arr);
                //////////////////////////////////////////////////////////////////////////////////
                this.dataVs.pedidos = pedidos;
                Cache.setLocal(session.catalog_pedidos, new Array());
                Cache.setLocal(session.cart_pedidos, null);
              }
            }
          }
        }

        if (this.arrayAutoventa != null && this.arrayAutoventa != undefined) {
          if (Array.isArray(this.arrayAutoventa)) {
            if (this.arrayAutoventa.filter(x => !x.SENT).length > 0) {
              let autovent = this.arrayAutoventa;
              for(let au of autovent) {
                if(au.CODIGLOB == -1) {
                  au.CODIGLOB = ' ';
                }
              }
              await this.sendAutoventa(autovent);
              for (let i of autovent) {
                i.SENT = true;
              }
              autovent = this.arrayAutoventa;
              if (this.auth.offonline) {
                await this.DatatempService.delete_data(['facturas_autoventa']);
                let arr = [];
                for (let ped of autovent) {
                  arr.push(
                  {
                    ruta: this.auth.user.id_ruta,
                    data_type: 'facturas_autoventa',
                    modify: false,
                    data_json: ped
                  });
                }
                await this.DatatempService.add_many_data(arr);
              } else {
                this.dataVs.facturas_autoventa = autovent;
                Cache.setLocal(session.catalog_pedidos, new Array());
                Cache.setLocal(session.cart_pedidos, null);
              }
            }
          }
        }

        if (this.arrayFacturas != null && this.arrayFacturas != undefined) {
          if (Array.isArray(this.arrayFacturas)) {
            if (this.arrayFacturas.filter(x => !x.SENT).length > 0) {
              for(let fact of this.arrayFacturas) {
                if(fact.CODIGLOB == -1) {
                  fact.CODIGLOB = ' ';
                }
              }
              this.errFacturas = [];
              let boo = await this.sendFacturas(this.arrayFacturas);
              if (boo) {
                for (let fact of this.arrayFacturas) {
                  if (this.errFacturas.filter(x => x == fact.NUMEROFACTURA).length == 0) {
                    fact.SENT = true;
                  }
                }
                this.errFacturas = [];
                if (this.auth.offonline) {
                  await this.DatatempService.delete_data(['facturas']);
                  let arr = [];
                  for (let fact of this.arrayFacturas) {
                    arr.push(
                    {
                      ruta: this.auth.user.id_ruta,
                      data_type: 'facturas',
                      modify: false,
                      data_json: fact
                    });
                  }
                  await this.DatatempService.add_many_data(arr);
                } else {
                  ///////////////////////////////////////////////////////////////////
                  await this.DatatempService.delete_data(['facturas']);
                  let arr = [];
                  for (let fact of this.arrayFacturas) {
                    arr.push(
                    {
                      ruta: this.auth.user.id_ruta,
                      data_type: 'facturas',
                      modify: false,
                      data_json: fact
                    });
                  }
                  await this.DatatempService.add_many_data(arr);
                  /////////////////////////////////////////////////////////////////////
                  this.dataVs.facturas = this.arrayFacturas;
                }
                await this.DatatempService.add_many_data([{
                                      ruta: this.auth.user.id_ruta,
                                      data_type: 'act_factura',
                                      modify: true,
                                      data_json: this.actFactura
                                    }]);
                await this.setConfig();
              }
            }
          } 
        }

        if (has_visit) {

          let visitas_array = this.arrayVisitas.filter( (item) => {
              return (item.MINUVISI !== "");
          });
        	if (Array.isArray(visitas_array)) {
	            if (visitas_array.length) {
	              await this.sendVisitas(visitas_array); 
	            }
          	}
        }

        if (has_cobra) {//Enviar Cobranzas
          await this.readyRecibos();
        }
        

        if (this.wasSent) {
          Cache.setLocal(session.lastSent, moment(new Date()).format('DD/MMM h:mm a'));
        }
        this.sendingData.close();
        if(!this.respcobra.success){
          throw 'Error al enviar cobranzas, '+this.respcobra.msj;
        }
        Swal.mixin(this.alertSuccess).fire();
        if(nologout){
          this.dataVs.even_send_data_end.emit({send:true});
        }
        if (this.duplicatedInvoice.result) {
          this.InvoiceDuplicatedService.openModal_emitter.emit({Errores: this.duplicatedInvoice.obj, last_insert: this.duplicatedInvoice.last_insert});
          this.duplicatedInvoice.result = false;
        }
      } catch (error) {
        if(error===false){
          error.message = "Error en la conexion";
        }
        Swal.mixin({
          icon: 'info',
          text: error.message,
          showConfirmButton: false,
          confirmButtonText: 'Cerrar',
        }).fire();
      }
  }

  //4.1 Proceso de enviado de recibos
  //Añadida cola de envio
  async readyRecibos(){
      //comprobar recibos que no tengan abonos por asignar
      let limit = 7;
      let conteo = 0;
      let recibos_send = this.arrayCobranzas.recibos.filter( (item) => {
        if(conteo < limit){
          const isvalid = item.sent == false && (item.total_cobradoME < 0.01 && !item.docus.some( (v) => v.ABONOSME < 0.01 ));
          if(isvalid){
            conteo++;
            item.en_cola = true;
          }
          return isvalid;
        }else{
          return false;
        }
      });
      //En backend añadir mas comprobaciones de errores
      if(conteo > 0){
        for (const k in recibos_send) {
          if (Object.prototype.hasOwnProperty.call(recibos_send, k)) {
            const elem = recibos_send[k];
            elem.total_asignado = this.cobraSv.redondeaFloat(elem.total_asignado ,8);
            elem.total_asignadoME = this.cobraSv.redondeaFloat(elem.total_asignadoME ,8);
            elem.total_cobrado = this.cobraSv.redondeaFloat(elem.total_cobrado ,8);
            elem.total_cobradoME = this.cobraSv.redondeaFloat(elem.total_cobradoME ,8);
            elem.total_pendiente = this.cobraSv.redondeaFloat(elem.total_pendiente ,8);
            elem.total_pendienteME = this.cobraSv.redondeaFloat(elem.total_pendienteME ,8);
            elem.tasa_prom = this.cobraSv.redondeaFloat(elem.tasa_prom ,8);
            elem.total_cobrado_base = this.cobraSv.redondeaFloat(elem.total_cobrado_base ,8);
          }
        }
        let enviados = false;
        let cobranzas:any;
        await this.sendCobranzas(recibos_send).then( (resp) =>{
          //Todas las cobranzas se cambian a enviadas
          cobranzas = this.arrayCobranzas;
          //Añadir sistema de recibos enviados y no enviados, cuadro en home vel y modal para detallar los recibos enviados y no enviados (link en los no enviados)
          //añadir variable para pendiente
          for (let reci of cobranzas.recibos) {
            if(reci.total_cobradoME < 0.01 && !reci.docus.some( (v) => v.ABONOSME < 0.01 )){
              //comprobar en msd
              if(reci.en_cola){
                reci.en_cola = false;
                reci.sent = true;
                for (const k in reci.docus) {
                  if (Object.prototype.hasOwnProperty.call(reci.docus, k)) {
                    reci.docus[k]['cobranza'].SENT = true;
                  }
                }
              }
            }else{
              reci.pendiente = true;
            }
          }
          cobranzas = this.cobraSv.cleanRecibos(cobranzas);
          this.arrayCobranzas = cobranzas;
          enviados = true;
        }).catch( (err)=>{
          this.respcobra = {
            success: false,
            msj: err
          };
        });
        if(enviados){
          if (this.auth.offonline) {
            await this.DatatempService.delete_data(['cobranzas']);
            await this.DatatempService.delete_data(['cobranzas_4_0']);
            await this.cobraSv.setDataTemp(cobranzas, false);
          } else {
            this.dataVs.recibos = cobranzas;
          }
          this.cobraSv.getCuentasCobrar(false,{},this.dataVs,'');
          this.wasSent = true;
          await this.readyRecibos();
        }else{
          for (let reci of  this.arrayCobranzas.recibos) {
            reci.en_cola = false;
          }
        }
      }
  }
  
  sendFacturas(facturas: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('setFacturas'),
        auth: this.auth,
        body: {
          factura: JSON.stringify(this.checkIndexOrders(facturas.filter(x => !x.SENT))).replace(/&/g, "^")
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if(resp.info) {
            /*Swal.fire(
              'Atención!',
              resp.message,
              'warning'
            );*/
            if (resp.Errores != undefined) {
              let e = true;
              for (let er of resp.Errores) {
                this.errFacturas.push(er.numfact);
                if (er.code == 1062 && e) {
                  let obj = resp.Errores.filter(x => x.code == 1062);
                  this.duplicatedInvoice.result = true;
                  this.duplicatedInvoice.obj = obj;
                  this.duplicatedInvoice.last_insert = resp.last_insert;
                  e = false;
                }
              }
              resolve(true);
            }
          }
        }
        this.wasSent = true;
        resolve(true);
      }).catch(error => {
        this.EventosService.auditar_error_vel(this.checkIndexOrders(facturas.filter(x => !x.SENT)),'facturas_vel');
        reject(error);
      });
    });
  }

  sendVisitas(visitas: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('setVisitas'),
        auth: this.auth,
        body: {
          ruta: this.auth.user.id_ruta,
          visita: visitas
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        let visitados = Cache.getLocal(session.visitados, true);

        if((visitados != undefined && visitados != null) &&  (visitas != undefined && visitas != null)){
          for(let i = 0; i< visitas.length; i++){
            for(let j = 0; j< visitados.length; j++){
              if(visitas[i].CODICLIE == visitados[j].CODICLIE){
                visitados[j].SENT = true;
              }
            }
          }
        }
        Cache.setLocal(session.visitados, visitados);
        this.wasSent = true;
        resolve(resp.success);
      }).catch(error => {
        this.EventosService.auditar_error_vel(visitas,'visitas_vel');
        reject(error);
      });
    });
  }

  setConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('actualizar_estado_factura'),
        auth: this.auth,
        body: {
          config: this.actFactura,
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        this.wasSent = true;
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  sendCobranzas(recibos_send): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('send_cobranzas'),
        auth: this.auth,
        body: {
          recibos: recibos_send,
          ruta: this.auth.user.id_ruta,
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        resolve(resp.success);
      }).catch(error => {
        this.EventosService.auditar_error_vel(recibos_send,'cobranza_vel');
        reject(error);
      });
    });
  }

  checkIndexOrders(obj: any): any {
    for (let p of obj) {
      if (p['LATITUD'] == undefined || p['LONGITUD'] == undefined) {
        p['LATITUD'] = 10.662798;
        p['LONGITUD'] = -71.614918;
      }
      /*if (typeof p['TASA'] == 'undefined') {
        p['TASA'] = 0;
      }*/
    }
    return obj;
  }

  sendAutoventa(autoventa: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('autoventa_enviar'),
        auth: this.auth,
        body: {
          data: autoventa.filter(x => !x.SENT)
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  sendPedidos(pedidos: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('setPedidos'),
        auth: this.auth,
        body: {
          pedido: JSON.stringify(this.checkIndexOrders(pedidos.filter(x => !x.SENT))).replace(/&/g, "^")
        }
      };
      const configAjax: Config2 = {
        retry_requests: 1,
        alerts: false,
        retry: true
      };
      const ajax = new Ajax2(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if(resp.info) {
            Swal.fire(
              'Atención!',
              resp.message,
              'warning'
            );
            this.errPedidos = resp.Encabezados;
          }
        }
        this.wasSent = true;
        resolve(resp.success);
      }).catch(error => {
        this.EventosService.auditar_error_vel(this.checkIndexOrders(pedidos.filter(x => !x.SENT)),'pedidos_vel');
        reject(error);
      });
    });
  }
}
