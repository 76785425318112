import { Injectable } from '@angular/core';

import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';
import { DataVelService } from '../synchronization/data-vel.service';
import { Cache } from 'src/app/core/utils/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { FormatCurrencyService } from 'src/app/core/services/format-currency.service';
import { DataTemp } from 'src/app/core/interfaces/vel/datatemp';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatatempService {

  specialChars = [  // Arreglo para los simbolos especiales en su valor ascii 
                    // para ser reemplazados al momento de leerse/guardarlos
                    // Se usa en caso de problemas de JSON Parse
    {
      symbol: '&',
      ascii: '38'
    }
  ]

  constructor(public auth: AuthService, 
    public dataVs: DataVelService, 
    private listUrl: UrListService,
    public fcurrency: FormatCurrencyService) { }

  //Obtiene los registros del datatemp especificado
  get_data(type): any {

    return new Promise((resolve, reject) => {
      if (this.auth.isLogin()) {
        const initAjax: Init = {
          method: 'post',
          url: this.listUrl.url('getDatatemp'),
          auth: this.auth,
          body: {
            ruta: this.auth.user.id_ruta,
            user_id: this.auth.user.idusuario,
            type: type
          }
        };
        const configAjax: Config = {
          visible: false
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then((reponse) => {
          let arr1 = this.decode_data(type,reponse.entities);
          resolve(arr1);
        }).catch(error => {
          resolve({cobranzas: []});
        });
      } else {
        resolve({cobranzas: []});
      }
    });
  }

  //Sincroniza los registros del modo offline/online al ser cambiados
  async set_all_mode() {
    if (this.auth.offonline) {  //Serializa los datos de la cache para ser mandados a las tablas
      let arr = [];
      let del = [];
      if (this.dataVs.pedidos != undefined && this.dataVs.pedidos != null) {
        if (this.dataVs.pedidos.length > 0) {
          del.push('pedidos');
          let pedidos = this.dataVs.pedidos;
          for (let ped of pedidos) {
            arr.push(
              {
                ruta: this.auth.user.id_ruta,
                data_type: 'pedidos',
                modify: false,
                data_json: this.JSONSerialize(ped)
              }
            );
          }
        }
      }
      if (this.dataVs.facturas != undefined && this.dataVs.facturas != null) {
        if (this.dataVs.facturas.length > 0) {
          del.push('facturas');
          let facturas = this.dataVs.facturas;
          for (let fact of facturas) {
            arr.push(
              {
                ruta: this.auth.user.id_ruta,
                data_type: 'facturas',
                modify: false,
                data_json: this.JSONSerialize(fact)
              }
            );
          }
        }
      }

      if (this.dataVs.facturas_autoventa != undefined && this.dataVs.facturas_autoventa != null) {
        if (this.dataVs.facturas_autoventa.length > 0) {
          del.push('facturas_autoventa');
          let facturas = this.dataVs.facturas_autoventa;
          for (let fact of facturas) {
            arr.push(
              {
                ruta: this.auth.user.id_ruta,
                data_type: 'facturas_autoventa',
                modify: false,
                data_json: this.JSONSerialize(fact)
              }
            );
          }
        }
      }

      let cobranz = this.dataVs.cobranzas;
      if (cobranz && cobranz.hasOwnProperty('encabezados') && cobranz.encabezados.length > 0){
        del.push('cobranzas');
        arr.push(
          {
            ruta: this.auth.user.id_ruta,
            data_type: 'cobranzas',
            modify: false,
            data_json: this.JSONSerialize(cobranz)
          }
        );
      }
      
      if(del.length > 0) {
        await this.delete_data(del);
      }
      
      if (this.dataVs.act_factura != undefined && this.dataVs.act_factura != null) {
        arr.push(
          {
            ruta: this.auth.user.id_ruta,
            data_type: 'act_factura',
            modify: true,
            data_json: this.JSONSerialize(this.dataVs.act_factura)
          }
        );
      }

      if (this.dataVs.control_autoventa != undefined && this.dataVs.control_autoventa != null) {
        arr.push(
          {
            ruta: this.auth.user.id_ruta,
            data_type: 'control_autoventa',
            modify: true,
            data_json: this.JSONSerialize(this.dataVs.control_autoventa)
          }
        );
      }

      if (arr.length > 0) {
        await this.add_many_data(arr);
      }
      
      Cache.removeItem(session.pedidos);
      Cache.removeItem(session.act_factura);
      Cache.removeItem(session.facturas);
      Cache.removeItem(session.cobranzas);
      Cache.removeItem(session.control_autoventa);
      Cache.removeItem(session.facturas_autoventa);

    } else {  //Obtiene los datos par ser agregados a la caché en el modo offline
      await this.get_data(['pedidos', 'facturas_autoventa', 'facturas', 'act_factura', 'cobranzas', 'control_autoventa']).then(resp => {
        Cache.setLocal(session.facturas, resp['facturas']);
        Cache.setLocal(session.pedidos, resp['pedidos']);
        Cache.setLocal(session.facturas_autoventa, resp['facturas_autoventa']);
        Cache.setLocal(session.act_factura, resp['act_factura'][0]);
        Cache.setLocal(session.control_autoventa, resp['control_autoventa'][0]);
        if(resp['cobranzas'].length){
          this.dataVs.cobranzas = resp['cobranzas'][0];
        }
      });
    }
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }
  
  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob( str )));
  }

  get_date_json(objeto){
  	try{
  		if ("FECHA" in objeto) {
	    	return objeto["FECHA"];
		} else {
			return moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-DD HH:mm:ss');
		}
  	}catch(e){
  		return moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-DD HH:mm:ss');
  	}
  }

  //Permite agregar varios datos al datatemp
  add_many_data(new_data: DataTemp[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let date = moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-DD HH:mm:ss');		
      for (let va of new_data) {
      	date = this.get_date_json(va.data_json);
        va.data_json = this.JSONSerialize(va.data_json);
      }
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('setDatatemp'),
        auth: this.auth,
        body: {
          user_id: this.auth.user.idusuario, 
          version: 1.0,
          create_at: date, 
          data: new_data
      }}
      const configAjax: Config = {
        visible: false
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then((reponse) => {
        resolve(reponse);
      }).catch(error => {
        reject(error);
      });
    });
  }

  //Agrega unicamente un dato al datatemp sin necesidad de usar la interfaz
  add_data(new_data, type, modify): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('setDatatemp'),
        auth: this.auth,
        body: {
          user_id: this.auth.user.idusuario, 
          version: 1.0,
          create_at: moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-DD HH:mm:ss'), 
          data: [{
            ruta: this.auth.user.id_ruta,
            data_type: type,
            modify: modify,
            data_json : this.JSONSerialize(new_data)
          }]
      }}
      const configAjax: Config = {
        visible: false
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then((reponse) => {
        resolve(reponse);
      }).catch(error => {
        reject(error);
      });
    });
  }

  //Borra datos del datatemp recibiendo un arreglo, si recibe 'all' como parametro se borran todos los registros
  delete_data(type) {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('deleteDatatemp'),
        auth: this.auth,
        body: {
          ruta: this.auth.user.id_ruta,
          user_id: this.auth.user.idusuario,
          data_type: type
        }
      };
      const configAjax: Config = {
        visible: false
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then((reponse) => {
        resolve(reponse);
      }).catch(error => {
        reject(error);
      });
    });
  }

  JSONDeserialize(param){
    //param = param.replace(/\\"/g,'"');
    /*param = param.replace(/\\/g,"");
    let arr = param.split("");
    let obj = '';
    for (let i = 0; i < arr.length; i++) {
        if ((param.charCodeAt(i) == 92 && arr[i+1] != '"') && i+1 < arr.length) {
            continue;   
        }
        obj = obj + arr[i];
    }
    while (obj.charAt(0) == '"' && obj.charAt(obj.length-1) == '"') {
        obj = obj.substring(1);
        let aux = '';
        for (let i = 0; i < obj.length-1; i++) {
            aux = aux + obj.charAt(i);
        }
        obj = aux;
    }
    return JSON.parse(obj);*/
    return JSON.parse(param);
  }

  JSONSerialize(param){
    return this.utf8_to_b64(JSON.stringify(param));//JSON.stringify(param);
  }

  undoSpecialChars(strg) {
    for (let i = 0; i < this.specialChars.length; i++) {
      //strg = strg.replace('.#/'+this.specialChars[i].ascii+'/#.', this.specialChars[i].symbol);
    }
    return strg;
  }

  replaceSpecialChars(strg) {
    for (let i = 0; i < this.specialChars.length; i++) {
      //strg = strg.replace(this.specialChars[i].symbol, '.#/'+this.specialChars[i].ascii+'/#.');
    }
    return strg;
  }

  checkJSON(data: any) {  //Chequea si el JSON tiene algún carácter especial para ser reemplazado por ASCII
    let result = data;
    let val = this.whatIsIt(data);
    if (val == "Array") {
      result = this.searchArray(data);
    } else if (val == "Object") {
      result = this.searchObject(data);
    } else if (val == "String") {
      result = this.replaceSpecialChars(data);
    }
    return result;
  }

  searchArray(data: any) {
    let array = Array();
    for (let i = 0; i < data.length; i++) {
        let result = data[i];
        let val = this.whatIsIt(data[i]);
        if (val == "Array") {
          result = this.searchArray(data[i]);
        } else if (val == "Object") {
          result = this.searchObject(data[i]);
        } else if (val == "String") {
          result = this.replaceSpecialChars(data[i]);
        }
        array.push(result);
    }
    return array;
  }

  searchObject(obje: any) {
    let array = {};
    for (let obj in obje) {
        let value = obje[obj];
        let b = {};
        let type = this.whatIsIt(value);
        if (type == "Array") {
          value = this.searchArray(value);
        } else if (type == "Object") {
          value = this.searchObject(value);
        } else if (type == "String") {
          value = this.replaceSpecialChars(value);
        }
        let n = obj.toString();
        b[n] = value;
        Object.assign(array, b);
    }
    return array;
  }

  whatIsIt(object: any) {
    if (object === null) {
        return "null";
    }
    if (object === undefined) {
        return "undefined";
    }
    if (object.constructor === "test".constructor) {
        return "String";
    }
    if (object.constructor === [].constructor) {
        return "Array";
    }
    if (object.constructor === ({}).constructor) {
        return "Object";
    }
    if (!isNaN(object)) {
        return "Number";
    }
    {
        return "don't know";
    }
  }

  decode_data(type:any=[],data:any){
    let arr1 = {};
    for (let ty of type) {
      try {
        let json = data.find(x => x.name == ty).json;
        let arr = [];// colocar opcion para detectar si es array
        for (let c of json) {
          const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
          let dat:any;
          let stop = 0;//Contador para do while
          dat = JSON.parse(this.b64_to_utf8(c));
          if(typeof dat === 'string'){//Si la data no esta decodificada, se intenta de nuevo
            //Es posible que aun sea base64 aun siendo decodificado
            do {
              stop++;
              dat = JSON.parse(this.b64_to_utf8(dat));
            } while (typeof dat === 'string' && stop < 6);
          }
          arr.push(dat);
          //arr.push(this.JSONDeserialize(c));
        }
        arr1[ty] = arr;
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    return arr1;
  }

  OLD_JSONDeserialize(param){
    param = param.replace(/\\/g,"");
    let arr = param.split("");
    let obj = '';
    for (let i = 0; i < arr.length; i++) {
        if ((param.charCodeAt(i) == 92 && arr[i+1] != '"') && i+1 < arr.length) {
            continue;   
        }
        obj = obj + arr[i];
    }
    while (obj.charAt(0) == '"' && obj.charAt(obj.length-1) == '"') {
        obj = obj.substring(1);
        let aux = '';
        for (let i = 0; i < obj.length-1; i++) {
            aux = aux + obj.charAt(i);
        }
        obj = aux;
    }
    return JSON.parse(obj);
  }
}
