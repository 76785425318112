import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActFacturaService {

  modal: any;
  parentComponent: any;
  goToCatalog: boolean = false;
  closeModal: any;
  camps_hasChanged: EventEmitter<any> = new EventEmitter();
  closedModal: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
