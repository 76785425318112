import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

/*no del sistema*/
import { Init } from '../interfaces/init';
import { Config } from '../interfaces/config';
import { Ajax } from '../utils/ajax';
import { AuthService } from '../auth/auth.service';
import { UrListService } from '../url/url.service';

import { Cobrado, Cobrados, Encabezado, Recibo, Recibos } from 'src/app/core/interfaces/vel/cobranza';
import { DatatempService } from 'src/app/vel/datatemp/datatemp.service';

import { session } from 'src/app/core/constantes/sessiones';
import * as moment from 'moment';
import { isArray } from 'lodash';
import { Cuenta } from '../interfaces/vel/cuenta';
import { Cache } from 'src/app/core/utils/cache';

@Injectable({
  providedIn: 'root'
})
export class CobranzasVelService {
  //cobranzas 4.0

  /**
   * Se utiliza para mostrar el mismo nombre en N360 y en Vel. 
   * Se obtienen varios tipos de pago pero solo se muestra los que están en el objeto "paymentTypes" si los 
   * códigos son iguales.
   * Cabe destacar que el nombre que se va a mostrar es el que está en la base de datos.
   */
  paymentTypes: any ={
    "01": "Efectivo",
    "02": "Cheques", 
    "03": "Depósitos / Transferencias", 
    "04": "Pago con Tarjeta D/C",
  }

  _recibos: Cobrado = {
    ruta:'',
    recibos: []
  };
  newrecibo: Cobrados;

  _idrecibo: 1;//conteo de idrecibo temporal en el cache, este conteo es individual en cada dispositivo 
  
  loadingScreen = Swal.mixin({
    title: 'Verificando su operación...',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
  });

  pass_c_c = true;//Impide que las cuentas se vuelvan a pedir si ya hay una peticion en proceso

  
  constructor(
    public datatemp: DatatempService,
    private listUrl: UrListService,
    public auth: AuthService,
  ){
    // console.log('newcobra');
  }

  set recibos(cobrado: Cobrado) {//Cobranzas 4.0
    if(cobrado.hasOwnProperty('recibos') && isArray(cobrado.recibos)){
      cobrado = this.cleanRecibos(cobrado);
      if(this.datatemp.auth.offonline){
        this.setDataTemp(cobrado);
      }else{
        this.datatemp.dataVs.recibos = cobrado;
      }
      this._recibos = cobrado;
    }else{
      this.refreshRecibos();
    }
  } 

  get recibos(): Cobrado {// Cobranzas 4.0
    if(this.datatemp.auth.offonline){
      this.getDataTemp();
    }else{
      let recibocache = this.datatemp.dataVs.recibos;
      if(recibocache){
        this._recibos = recibocache;
      }else{
        this.refreshRecibos();
      }
    }
    this._recibos = this.cleanRecibos(this._recibos);
    if(this._recibos.ruta == ''){
      this._recibos.ruta = this.auth.user.id_ruta;
    }
    // console.log(this._recibos,'get',this.auth.user.id_ruta);
    return this._recibos;
  }

  refreshRecibos(){//Cobranzas 4.0
    this._recibos = {
      recibos: [],
      ruta: this.auth.user.id_ruta,
    } as Cobrado;
  }

  async setRecibos(cobrado: Cobrado, loading=true) {//Cobranzas 4.0
    if(cobrado.hasOwnProperty('recibos') && isArray(cobrado.recibos)){
      cobrado = this.cleanRecibos(cobrado);
      if(this.datatemp.auth.offonline){
        await this.setDataTemp(cobrado,loading);
      }else{
        this.datatemp.dataVs.recibos = cobrado;
      }
      this._recibos = cobrado;
    }else{
      this.refreshRecibos();
    }
  } 

  async getRecibos(loading=true):Promise<Cobrado>{//Cobranzas 4.0
    if(this.datatemp.auth.offonline){
      await this.getDataTemp(loading);
    }else{
      let recicache = this.datatemp.dataVs.recibos;
      if(recicache){
        this._recibos = recicache;
      }else{
        this.refreshRecibos();
      }
    }
    this._recibos = this.cleanRecibos(this._recibos);
    if(this._recibos.ruta == ''){
      this._recibos.ruta = this.auth.user.id_ruta;
    }
    // console.log('CobranzasVelService');
    return this._recibos;
  }

  async getDataTemp(loading=true){
    if(loading){this.loadingScreen.fire();}
    await this.datatemp.get_data(['cobranzas_4_0']).then(resp => {
      if(loading){this.loadingScreen.close();}
      if(typeof resp === 'object' && resp['cobranzas_4_0'].length){
        this._recibos = resp['cobranzas_4_0'][0];
      }else{
        this.refreshRecibos();
      }
    });
  }

  async setDataTemp(data, loading=true){
    let arr = [
      {
        ruta: this.datatemp.auth.user.id_ruta,
        data_type: 'cobranzas_4_0',
        modify: true,
        data_json: data,
      }
    ];
    if(loading){this.loadingScreen.fire();}
    await this.datatemp.add_many_data(arr).then(resp => {
      if(loading){this.loadingScreen.close();}
    }).catch( ()=>{
      if(loading){this.loadingScreen.close();}
    } );
  }

  /**
   * Verifica que los recibos con mas de 1 dia (que se hayan enviado) se eliminen
   * @param recib Recibe un Array de Recibos (Cobrado) o false. | false: Limpia Recibos del Servicio, sin Retornar ningun resultado.
   * @returns interfaz Cobrado
   */
  cleanRecibos(recib:any=false){
    //4.0
    //Limpia los recibos que se hayan enviado y tengan mas de 1 dia.
    //Recibe un Array de Recibos (Cobrado) o false.
    //False: para limpiar Recibos del Servicio, sin Retornar ningun resultado.
    //Array de Recibos: para limpiar los Recibos del Array, el resultado es Retornado.

    let recibos: Cobrado;
    if(recib==false){
      recibos = this._recibos as Cobrado;
    }else{
      recibos = recib;
      recib = true;
    };
    let fecha_hoy = moment();
    fecha_hoy.format('YYYY-MM-DD');
    recibos.recibos = recibos.recibos.filter( (item) => {
      //Cobranza 4.0
      //Ahora se debera mirar la fecha del Recibo (interfaz de Cobrado)
      //Cuando se añade o edita un docu de un recibo, se actualiza la fecha al ultimo detalle añadido
      let fecha_hecho = moment(item.fecha); //new Date(item.fecha);
      fecha_hecho.format('YYYY-MM-DD');
      fecha_hecho.add(1, 'days');
      if( item.sent ){
        if( moment(fecha_hecho).isSameOrBefore(fecha_hoy, 'day')){ //(fecha_hecho.unix() > fecha_hoy.unix())){
          return false;
        }
      }else{
        return true;
      }
      return true;
    });
    //Comprueba si hay inputs con montos que no han sido descontados del total abonado
    for (const k in recibos.recibos) {
      if (Object.prototype.hasOwnProperty.call(recibos.recibos, k)) {
        const recc = recibos.recibos[k];
        for (const ke in recc.docus) {
          if (Object.prototype.hasOwnProperty.call(recc.docus, ke)) {
            const docc = recc.docus[ke];
            if(docc.isvalid==''){
              docc.ABONOS = 0;
              docc.ABONOSME = 0;
              // docc.isvalid = 'is-valid';
            }
          }
        }
      }
    }
    //Comprueba si el recibo debe ponerse en estado Pendiente, si no cumple las condiciones
    recibos.recibos = this.checkPendientes(recibos.recibos);
    if(recib){
      return recibos;
    }else{
      this._recibos = recibos;
    }
  }

  getCatCobranzas(visible=true, titles:any={}, force_update=false, even_site='all'):Promise<any> {
    return new Promise((resolve, reject) => {
      this.pass_c_c = false;
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_catcobranzas'),
        auth: this.auth,
        body: {
          codiruta: this.auth.user.id_ruta
        }
      };
      const configAjax: Config = {
        visible,
        titles,
        force_update,
        numInten: 1,
        params: [
          {name: session.tipoCobro, find: 'tcobros'},
          {name: session.bancoNacional, find: 'bnacional'},
          {name: session.bancoPrivado, find: 'bprivado'},
          {name: session.cuentas, find: 'cuentas_cobrar'},
          {name: session.clientes, find: 'clientes'},
          {name: session.monedas, find: 'monedas'},
          {name: session.moneda_p, find: 'moneda_p'},
          {name: session.lista_tasas, find: 'lista_tasas'},
          {name: session.ver_me_cobra, find: 'ver_me_cobra'},
          {name: session.multi_docus_recibo, find: 'multi_docus_recibo'},
        ]
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        let cobranz:any;
        if(respo.hasOwnProperty('cobranzas') && respo.cobranzas.length){
          cobranz = this.datatemp.decode_data(['cobranzas_4_0'], respo.cobranzas);
          // respo.cobranzas = respo.cobranzas[0].json[0];
        }else{
          cobranz = {};
        }
        respo.cobranzas = [];
        if(cobranz.hasOwnProperty('cobranzas_4_0') && cobranz.cobranzas_4_0.length){
          respo.cobranzas = cobranz.cobranzas_4_0[0];
        }
        this.datatemp.dataVs.even_tipo_cobro.emit({tipo_cobro: respo.tcobros});
        this.datatemp.dataVs.even_bancos_priv.emit({bancos_privados: respo.bprivado});
        this.datatemp.dataVs.even_bancos_nac.emit({bancos_nacionales: respo.bnacional});
        this.datatemp.dataVs.even_cuentas_cobrar.emit({cuentas_cobrar: respo.cuentas_cobrar, even_site});
        Cache.setLocal(session.ver_me_cobra, respo.ver_me_cobra);
        this.pass_c_c = true;
        resolve(respo);
      }).catch(error => {
        this.pass_c_c = true;
        reject(error);
      });
    });
  }

  buscaEncabezado(cuenta: Cuenta):Encabezado{
    //Retorno la cobranza (encabezado) que encuentre con el mismo documento
    //Cobranza 4.0
    //Ahora se hace un bucle para revisar todos los recibos
    let cobran = this._recibos.recibos;
    if(Array.isArray(cobran)){
      for (const cob in cobran) {
        if (Object.prototype.hasOwnProperty.call(cobran, cob)) {
          const recib = cobran[cob];
          let cobr = recib.docus.find(item => (item['cobranza'].NUMEDOCU == cuenta.NUMEDOCU) && (item['cobranza'].TIPODOCU == cuenta.TIPODOCU));
          if(cobr != undefined){
            cobr.cobranza.has_recibo = true;
            cobr.cobranza.id_recibo = recib.idrecibo;
            cobr.cobranza.ABONOSME = cobr.ABONOSME;
            cobr.cobranza.ABONOS = cobr.ABONOS;
            return cobr.cobranza;
          } 
        }
      }
    }
    return {
      FECHRECI: null,
      fecha_creacion: null,
      CODICLIE: cuenta.CODICLIE,
      NUMEDOCU: cuenta.NUMEDOCU,
      TIPODOCU: cuenta.TIPODOCU,
      MONTO: cuenta.MONTO,
      ABONOS: 0,
      ABONOSME: 0,
      SENT: false,
      anadido: false,
      detalles: [],
      has_recibo: false,
      id_recibo: 0
    } as Encabezado;
  }

  /**
   * Verifica que los recibos con mas de 1 dia (que se hayan enviado) se eliminen
   * @param monto monto numerico a formatear
   * @param deci cantidad de decimales a formatear. Por defecto 2.
   * @returns monto formateado en float
   */
  redondeaFloat(monto: number, deci=2): number{
    //realiza un redondeo a la cantidad de deciamles dados.
    return parseFloat(monto.toFixed(deci));
  }

  checkPendientes(reci:Recibos){//Recorre los Recibos para verificar si su estado es Pendiente y se lo asigna
    for (const k in reci) {
      if (Object.prototype.hasOwnProperty.call(reci, k)) {
        const el = reci[k];
        if((el.total_cobradoME >= 0.01 || el.total_cobrado >= 0.01) || el.docus.some( (v) => v.ABONOSME < 0.01 )){
          reci[k].pendiente = true;
        }else{
          reci[k].pendiente = false;
        }
      }
    }
    reci = this.cleanPendientes(reci);
    return reci;
  }

  cleanPendientes(reci:Recibos){//Verifica que los pendientes tengan 3 horas o mas para borrarlos.
    //Cobranza 4.1
    let fecha_hoy = moment().format('YYYY-MM-DD HH:mm:ss');
    reci = reci.filter( (item) => {
      let fecha_hecho = moment(item.fecha).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');
      if( item.pendiente ){
        if( moment(fecha_hecho).isSameOrAfter(fecha_hoy, 'hours')){
          return true;
        }
      }else{
        return true;
      }
      return false;
    });
    return reci;
  }

  getCuentasCobrar(visible=true, titles:any={}, dataVs=undefined, even_site='all'):Promise<any> {
    return new Promise((resolve, reject) => {
      if(!this.pass_c_c){
        reject(true);
      }else{
        this.pass_c_c = false;
        const initAjax: Init = {
          method: 'post',
          url: this.listUrl.url('obtener_cuentascobrar'),
          auth: this.auth,
          body: {
            codiruta: this.auth.user.id_ruta
          }
        };
        const configAjax: Config = {
          visible,
          titles,
          time_update: 31540000001, //un año, ya que no se necesita actilizar las cobranzas
          params: {name: session.cuentas, find: 'entities'}
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(resp => {
          if(dataVs != undefined){
            dataVs.even_cuentas_cobrar.emit({cuentas_cobrar: resp.entities, even_site});
          }
          this.pass_c_c = true;
          resolve(resp);
          // resolve(resp.entities);
        }).catch(error => {
          this.pass_c_c = true;
          reject(error);
        });
      }
    });
  }

}
