import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
// import { SendDataService } from '../../synchronization/send-data.service';

@Component({
  selector: 'app-data-send-modal',
  templateUrl: './data-send-modal.component.html',
  styleUrls: ['./data-send-modal.component.scss'],
})
export class DataSendModalComponent implements OnInit {

  even_send_data: EventEmitter<any> = new EventEmitter();
  tipo: string;
  msj: string;

  sendata: any;

  botonoff = true;

  constructor(
    public ModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    if (this.tipo == 'ruta') {
      this.msj = 'Los Datos que no se hayan enviado se perderán; Antes de cambiar Ruta debe elegir si Enviar Datos o No.';
    } else if(this.tipo == 'logout') {
      this.msj = 'Los Datos que no se hayan enviado se perderán; Antes de Cerrar Sesion debe elegir si Enviar Datos o No.';
    }else{
      this.hideModal();
    }
  }

  hideModal(){
    this.ModalRef.hide();
  }

  async sendaData(send = 'enviar'){
    if(send == 'enviar'){
      this.botonoff = false;
      await this.sendata.startSendData(false);
    }
    this.even_send_data.emit(true);
    // this.botonoff = true;
    this.hideModal();
  }

}
