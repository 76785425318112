export class Search {

    static filter(data: Array<any>, filter: (x: any, e: any, i?: number) => void, text: string | number): Array<any> {
        return data.filter((val, key) => filter(val, text, key));
    }

    static position(data: Array<any>, filter: (x: any, e: any, i?: number) => void, value: string | number): number {
        return data.findIndex((val, key) => filter(val, value, key));
    }

    static igual(value: any, text: any) {
        if (value === text) {
            return true;
        }
        return false;
    }

    static search(value: any, text: any): boolean {
        if (value != null) {
            if (value.toString().toLowerCase().indexOf(text.toString().toLowerCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    static search_keyword(value: string, text: string) {
        if (value != null) {
            const text_split = text.split(" ");
            return Search.all(text_split.map( keyword => {
                if (keyword !== "") {
                    if (value.toString().toLowerCase().search(keyword.toString().toLowerCase()) !== -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }));
        }
        return false;
    }

    static any(iterable: Array<any>): boolean {
        for (const index in iterable) {
            if (iterable[index]) {
                return true;
            }
        }
        return false;
    }

    static all(iterable: Array<any>): boolean {
        for (const index in iterable) {
            if (!iterable[index]) {
                return false;
            }
        }
        return true;
    }

    static forEachAll(data, each, finish, sync) {
        let n = -1, result = [];
        let next = sync ?
            () => {
                if (++n < data.length) { each(data[n], n, result, next); }
                else if (finish) { finish(result); }
            } :
            (function () {
                function completed() {
                    if (++n <= data.length && finish) { finish(result); }
                }
                for (var i = 0; i < data.length; i++) { each(data[i], i, result, completed); }
                return completed;
            }());
        next();
    }
    

}
