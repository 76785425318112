import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
    
export class TextoService {
    Modal: any;
    closeModal: any;
    delimitador: string;
    campos:any;
    cabecera: any;
    tipo: any;
    opcion=null;

    mes:any= '';
    anio:any= '';
    filtro: boolean = false;
    type_modal: Number = 1;
    even_change_request_colpal: EventEmitter<any> = new EventEmitter();
    even_reconteo_request_colpal: EventEmitter<any> = new EventEmitter();

    campo_Routmast = ['DistributorCode', 'RouteCode', 'Operation_type', 'VisitFrequency', 'RouteName'];

    campo_Custmast = ['DistributorCode', 'CustomerCode', 'CustomerName', 'ChainCode', 'RouteCode', 'SegmentType', 'D2Track_job', 'D2Track_cp', 'CustomerCensusNumber', 'CustomerClass','PostalCode','City','State'];

    campo_Saleteam = ['DistributorCode', 'SalesTeamCode', 'SalesTeamName', 'Operation_type'];

    campo_Inventry = ['DistributorCode', 'WareHouseCode', 'Process', 'InventoryType', 'ProductCode', 'OpeningBalanceQty', 'OpeningBalanceValue', 'ReceiptQuantity', 'ReceiptValue', 'FreeReceiptQuantity', 'FreeReceiptValue', 'ReturnQuantity' , 'ReturnValue' ,'AdjustmentQuantity', 'AdjustmentValue', 'AllocatedQuantity', 'AllocatedValue', 'ClosingQuantity', 'ClosingValue'];

    campo_Disteffe = ['DistributorCode', 'Process', 'RouteCode', 'CustomerSegmentType', 'VisitFrequencyOfRoute', 'NoOfCustomers', 'NoOfNewCustomers', 'CustomersSalesOrder', 'NoOfOrders', 'OrderAmount', 'ProductsAverage', 'CasesOrdered', 'CasesDelivered', 'SalesTeamCode', 'City', 'State'];

    campo_Sales = ['DistributorCode', 'Month', 'ProductCode', 'CustomerCode' , 'SalesTeamCode', 'SalesQty', 'SalesValue', 'FreeQty', 'LandValue', 'NoOfOrders', 'NetValue', 'Week', 'Day'];


    /////////////////////////////////////////DIANOSTICO////////////////////////////////////////////////////////////////
    campo_rutas = ['Codigo Ruta: Este campo es el codigo de ruta al que se le asigna a cada vendedor.', 'Letra: Este campo detemina el tipo de ruta que pertenece ese vendedor, ya sea vendedor directo D, o V indirecto.','Nombre: Nombre de vendedor al que se esta asignado la ruta'];

    campo_cliente = ['Codigo: Este campo es el codigo de cliente.', 'Nombre: Este campo detalla el nombre del cliente asociado al codigo.', 'ChainCode: Este es el codigo chain del cliente, vease paso 4 del manual hed Colgate.','SegmentCode: Este campo determina el codigo de segmento del cliente, vease paso 3 del manual hed Colgate.'];

    campo_ventas = ['Documento: Este campo corresponde al numero de documento de la trasaccion.', 'Tipo: Este campo corresponde al tipo de documento.', 'Codiclie: Codigo asociado al cliente a la venta.', 'Codiprod: Codigo del producto asociado a al numero de documento.', 'Codigo SAP: Este campo es el que determina el codigo del producto COPAL, vease paso 2 del manual hed Colgate. \n El valor del campo debe corresponder a un codigo valido de COLPAL','Chain: Este es el codigo chain del cliente, vease paso 4 del manual hed Colgate.','SegmentCode: Este campo determina el codigo de segmento del cliente, vease paso 3 del manual hed Colgate.','Ruta: Este campo determina el codigo de ruta asociada al cliente que hizo la trasaccion','Fecha: Este campo determina la fecha en que se realizo la trasaccion.'];

    campo_producto = ['Codigo: Este campo es el codigo de producto.', 'Codigo SAP: Este campo es el que determina el codigo del producto COPAL, vease paso 2 del manual hed Colgate. \n El valor del campo debe corresponder a un codigo valido de COLPAL',  'Descripcion: Este es la descripcion del producto asociado al codigo.'];
    
    cabeceras(numero){
        if(numero == 1){
            this.campos = this.campo_Routmast;
        }
        if(numero == 2){
            this.campos = this.campo_Custmast;
        }
        if(numero == 3){
            this.campos = this.campo_Saleteam;
        }

        if(numero == 4){
            this.campos = this.campo_Inventry;
        }
        if(numero == 5){
            this.campos = this.campo_Disteffe;
        }

        if(numero == 6){
            this.campos = this.campo_Sales;
        }
        /*
        if(numero == 7){
            this.campos = this.campo_Prodmast;
        }

        if(numero == 8){
            this.campos = this.campo_Chain;
        }
        if(numero == 9){
            this.campos = this.campo_Custseg;
        }
        if(numero == 10){
            this.campos = this.campo_Otypmast;
        }*/
        
    }

    downloadFile(data, filename='data') {
        let csvData = this.ConvertToCSV(data, this.campos);
        
        let dwldLink = document.createElement("a");
        dwldLink.setAttribute('href','data:text/plain;charset=utf-8,'+ encodeURIComponent(csvData));
        dwldLink.setAttribute("download", filename + ".txt");
        dwldLink.style.display = 'none';

        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    
    ConvertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

            for (let i = 0; i < array.length; i++) {
                let line='';
                for (let index in headerList) {
                    let head = headerList[index];

                    if(array[i][head] != undefined){
                        line += array[i][head] + '\t';      
                    }
                    else{
                        line +='\t';
                    }
                }
                line = line.slice(0, -1);
                str += line + '\r\n';
            }
        return str;
    }



    texto_referencia(id){
      if(id==0){
          return 'El diagnostico permite determinar si una ruta dada NO cumple con la estructura requerida por el ITT Colgate. Usualmente esto es requerido cuando el cliente detecta que para los archivos Routmast4.txt y Saleteam4.txt se presenta ausencia de alguna ruta que deberia ser reportada. Este diagnostico explora ciertos campos asociados a la estructura de la ruta requerida. El tal sentido se debe introducir en el campo el codigo de la ruta y presionar el boton de diagnosticar.';
      }
      else if(id==1){
          return 'El diagnostico permite determinar si un cliente dado NO cumple con la estructura requerida por el ITT Colgate. Usualmente esto es requerido cuando el cliente detecta que para el archivo Custmast4.txt se presenta ausencia de algun registro que deberia ser reportado. Este diagnostico explora ciertos campos asociados a la estructura de Cliente requerida. El tal sentido se debe introducir en el campo el codigo de cliente y presionar el boton de diagnosticar.';
      }
      else if(id==2){
          return 'El diagnostico permite determinar si una venta dada NO cumple con la estructura requerida por el ITT Colgate. Usualmente esto es requerido cuando el cliente detecta que para el archivo Sales4.txt se presenta ausencia de alguna venta que deberia ser reportada. Este diagnostico explora ciertos campos asociados a la estructura de ventas requerida. El tal sentido se debe introducir en el campo el numero de documento y presionar el boton de diagnosticar.';
      }
      else if(id==3){
          return 'El diagnostico permite determinar si un producto dado NO cumple con la estructura requerida por el ITT Colgate. Usualmente esto es requerido cuando el cliente detecta que para los archivo Inventry4.txt, Sales4.txt y Disteffe4.txt se presenta ausencia de algun producto que deberia ser reportado. Este diagnostico explora ciertos campos asociados a la estructura de producto requerida. El tal sentido se debe introducir en el campo el codigo de producto o por su codigo SAP del mismo, y presionar el boton de diagnosticar.';
      }
      else{
          return 'S/N';
      }
    }


    texto_resultado(id){

      if(id==0){
        return 'resultados dados';
      }

      return '';
    }
}