import { session } from "../constantes/sessiones";
import { Config, Param, Params } from "../interfaces/config"
import { Init } from "../interfaces/init";
import { Cache } from "./cache";

export class Estorage {

    private params: Param | Params;
    private save_all: boolean;
    private time_update: number;
    private force_update: boolean;
    private auth:any;

    constructor(params: Param | Params, time_update: number = 1800000, force_update: boolean = false, save_all: boolean = false, auth:any = false) {
        this.params = params;
        this.time_update = time_update;
        this.force_update = force_update;
        this.save_all = save_all;
        this.auth = auth;
    }

    public get_params(): Promise<any> {
        return new Promise( async (resolve, reject) => {
            if(this.auth.offonline){
                reject(false);
            }else if (this.force_update == false && this.params != null) {
                let obj = {success: true};
                if (Array.isArray(this.params)) {
                    for (const val of this.params) {
                        try {
                            let resp: any = await Cache.get_data_db(val.name);
                            if (resp) {
                                const data = resp;
                                const tiempo_sumado = data.time + this.time_update;
                                if (new Date().getTime() < tiempo_sumado) {
                                    obj[val.find] = data.obj;
                                } else {
                                    reject(false);
                                }
                            } else {
                                reject(false);
                            }
                        } catch (error) {
                            reject(false);
                        }
                    }
                    resolve(obj);
                } else {
                    try {
                        let resp: any = await Cache.get_data_db(this.params.name);
                        if (resp) {
                            const data = resp;
                            const tiempo_sumado = data.time + this.time_update;
                            if (new Date().getTime() < tiempo_sumado) {
                                obj[this.params.find] = data.obj;
                            } else {
                                reject(false);
                            }
                            resolve(obj);
                        } else {
                            reject(false);
                        }
                    } catch (error) {
                        reject(false);
                    }
                }
            } else {
                reject(false);
            }
        });
    }

    public set_params (resp: any) {
        if(this.auth.offonline){
            return false;
        }else if (typeof this.params !== 'undefined' && this.params !== null) {
            if (Array.isArray(this.params)) {
                for (const val of this.params) {
                    const data = {time: new Date().getTime(), obj: resp[val.find]}
                    Cache.save_data_db(val.name, data).then( ss =>{}).catch( err=>{});
                }
            } else {
                const data = {time: new Date().getTime(), obj: resp[this.params.find]}
                Cache.save_data_db(this.params.name, data).then( ss =>{}).catch( err=>{});
            }
        }
    }

    public depuring_init(data: Init) {
        const init = {
            url: data.url.url(),
            body: data.body,
            method: data.method
        }
        return init;
    }

    public list_urls(init: Init, config: Config) {
        if (Cache.getLocal(session.list_pen)) {
            let obj = Cache.getLocal(session.list_pen, true);
            if (typeof obj[config.send_category] === 'undefined') {
                obj[config.send_category] = [];
            }
            obj[config.send_category].push({init: this.depuring_init(init), config: config, status: 'pendiente'});
            Cache.setLocal(session.list_pen, obj);
        } else {
            let obj = {};
            obj[config.send_category] = [];
            obj[config.send_category].push({init: this.depuring_init(init), config: config, status: 'pendiente'});
            Cache.setLocal(session.list_pen, obj);
        }
    }

}