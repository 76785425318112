import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { TitlesService } from '../services/titles.service';
import { rutasUrl } from 'src/app/core/constantes/rutas';

@Component({
  selector: 'app-notfoundpage',
  templateUrl: './notfoundpage.component.html',
  styleUrls: ['./notfoundpage.component.scss'],
  inputs: ['error_txt','desc_txt','ir_a_txt','ir_a_url']
})
export class NotfoundpageComponent implements OnInit {

  error_txt: string;
  desc_txt: string;
  ir_a_txt: string;
  ir_a_url: string;
  ir_a2 = false;
  ir_a_txt2: string;
  ir_a_url2: string;
  title = 'Error 404'

  constructor(
    public titles: TitlesService,
    public auth: AuthService
  ) {

    this.titles.setTitle(this.title);
     
    if (!this.error_txt || !this.desc_txt || !this.ir_a_txt || !this.ir_a_url){
      this.error_txt = "ERROR 404";
      this.desc_txt = "La página a la que intenta acceder no está disponible.";
      this.ir_a_txt = "Negocio 360°";
      this.ir_a_url = rutasUrl.homen360;
    }
    if (this.auth.isVel() && this.auth.user.rutas.length){
      this.ir_a_txt2 = "Ventas en Linea";
      this.ir_a_url2 = rutasUrl.hometdp;
      this.ir_a2 = true
    }
    this.titles.titlebar = this.error_txt;
  }

  ngOnInit() {}

}
