import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { category_send } from '../constantes/categorys';
import { ListUrl } from '../constantes/request';
import { session } from '../constantes/sessiones';
import { Config2 } from '../interfaces/config';
import { Init } from '../interfaces/init';
import { UrlBase } from '../url/url';
import { Cache } from '../utils/cache';
import { Search } from '../utils/search';
import Ajax2 from '../utils/ajax2';

@Injectable({
  providedIn: 'root'
})
export class AutoSendService {

  lista_send: any;
  lista_category = category_send;

  constructor(public auth: AuthService) {

    if (Cache.getLocal(session.list_pen)) {
      this.lista_send = Cache.getLocal(session.list_pen, true);
      this.call();
    } else {
      this.lista_send = null;
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async call() {
    await this.sleep(30000);
    await this.init();
    this.call();
  }

  async init() {
    try {
      await this.check_internet();
      this.lista_send = Cache.getLocal(session.list_pen, true);
      await this.proceso();
      return true;
    } catch (error) {
      return false;
    }
  }

  check_internet() {
    return new Promise((resolve, reject) => {
      const init: Init = {
        auth: this.auth,
        method: 'post',
        url: new UrlBase(ListUrl.check_internet)
      }
      const config: Config2 = {
        alerts: false, 
        retry_requests: 4,
        authenticate: false
      };
      const ajax = new Ajax2(init, config);
      ajax.call().then((resp) => {
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }

  lista_pendiente (category: string) {
    return this.lista_send[category].reduce((ar: any, app: any) => {
      if (app.status == 'pendiente') {
        ar.push(app);
      }
      return ar;
    }, []);
  }

  pendd() {
    let close = false;
    if (Cache.getLocal(session.list_pen)) {
      const arr = this.lista_category.reduce((a, b) => {
        if (typeof this.lista_send[b.name] !== 'undefined' && this.lista_send[b.name].length){
          a.push(b);
        }
        return a;
      }, []);
      close = arr.length==0?false:true;
    }
    return close;
  }

  send_category(category: string) {
    return new Promise((resolve, reject) => {
      const list = this.lista_pendiente(category);
      if (list.length) {
        Search.forEachAll(list, (value, key, result, next) => {
          this.lista_send[category][key].status = 'send';
          Cache.setLocal(session.list_pen, this.lista_send);
          const init: Init = {
            auth: this.auth,
            method: value.init.method,
            url: new UrlBase(value.init.url),
            body: value.init.body
          }
          const config: Config2 = {
            alerts: false, 
            retry_requests: 2,
            validate: (resp) => {
              return new Promise((resolve, reject) => {
                resolve(resp);
              });
            }
          };
          const ajax = new Ajax2(init, config);
          ajax.call().then((resp) => {
            if (resp.success) {
              this.lista_send[category][key].splice(key, 1);
              next();
            } else {
              this.lista_send[category][key].status = 'error_server';
              this.lista_send[category][key].resp = resp;
              Cache.setLocal(session.list_pen, this.lista_send);
              next();
            }
          }).catch((err) => {
            this.lista_send[category][key].status = 'error_innes';
            Cache.setLocal(session.list_pen, this.lista_send);
            next();
          });
          
        }, (all) => {
          resolve(true);
        }, true);
      } else {
        resolve(true);
      }
    });
  }

  changestatus(sn, i) {
    this.lista_send[sn.config.send_category][i].status = 'pendiente';
    Cache.setLocal(session.list_pen, this.lista_send);
  }

  removeElement(sn, i) {
    this.lista_send[sn.config.send_category].splice(i, 1);
    Cache.setLocal(session.list_pen, this.lista_send);
  }

  proceso() {
    return new Promise((resolve, reject) => {
      Search.forEachAll(this.lista_category, (value, key, result, next) => {
        if (value.depends) {
          if (Array.isArray(value.depends)) {
            let ready = [];
            for (let val of value.depends) {
              if (typeof this.lista_send[val] !== 'undefined' && this.lista_send[val].length == 0) {
                ready.push(true);
              } else {
                ready.push(false);
              }
            }
            if (Search.all(ready)) {
              if (typeof this.lista_send[value.name] !== 'undefined' && this.lista_send[value.name].length != 0) {
                this.send_category(value.name).then(() => {
                  next();
                }).catch((err) => {
                  next();
                });
              }
            } else {
              next();
            }
          } else {
            if (typeof this.lista_send[value.depends] !== 'undefined' && this.lista_send[value.depends].length == 0) {
              if (typeof this.lista_send[value.name] !== 'undefined' && this.lista_send[value.name].length != 0) {
                this.send_category(value.name).then(() => {
                  next();
                }).catch((err) => {
                  next();
                });
              } else {
                next();
              }
            } else {
              next();
            }
          }
        } else {
          if (typeof this.lista_send[value.name] !== 'undefined' && this.lista_send[value.name].length != 0) {
            this.send_category(value.name).then(() => {
              next();
            }).catch((err) => {
              next();
            });
          } else {
            next();
          }
        }
      }, (all) => {
        resolve(true);
      }, true);
    });
  }

}
