import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { category_send } from '../constantes/categorys';
import { AutoSendService } from '../services/auto-send.service';

@Component({
  selector: 'app-list-pendientes',
  templateUrl: './list-pendientes.component.html',
  styleUrls: ['./list-pendientes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListPendientesComponent implements OnInit {

  categorys = category_send;

  constructor(public mybsModalRef: BsModalRef, public send: AutoSendService) { }

  ngOnInit() {
    
  }


  errorshow(sn: any, i: any) {
    const options: SweetAlertOptions = {
      icon: 'error',
      title: sn.status=='error_server'?sn.resp.message:'Error innesperdo',
      text: 'Quiere Reintentar?',
      confirmButtonText: 'Reintentar',
      showConfirmButton: true,
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      cancelButtonText: 'Calcelar',
      showCloseButton: true,
      preConfirm: (bton) => {
          this.send.changestatus(sn, i);
      }
    };
    const alerta = Swal.fire(options);
  }

  removeElement(sn: any, i: any) {
    this.confir(`Quiere eliminar el envio ?`, `Eliminar Envio`).then(() => {
      this.send.removeElement(sn, i);
    });
  }


  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText:
      'Cancelar',
    cancelButtonAriaLabel: 'Cancelar'
  });

  con(action: () => void, title: string, templa: string, icon: any) {
    this.confirmar.fire({
      icon,
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  confir(html: string, title: string, icon = 'question'): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.con(() => {
            this.close_confirmar().then(() => {
                resolve(true);
            });
        }, title, html, icon);
    });
}
}
