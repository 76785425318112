import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmTobackService {
  activeToback = false;//indica si en el componente actual tiene tobackguard
  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Esta seguro de abandonar la pagina?');
    return of(confirmation);
  };
}
