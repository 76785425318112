import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { GoogleAnalyticsSevice } from 'src/app/core/services/google-analytics.service'

if (environment.production) {
  enableProdMode();
}

GoogleAnalyticsSevice.addScriptGoogleCode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
