import { Cache } from '../utils/cache';
import { session } from '../constantes/sessiones';

export class Token {

    public _token: null | string;
    tokenObj: any;
    type: string;
    name: string;

    constructor(token: string) {
        this._token = null;
        this.proceso(token);
    }

    proceso(token: string) {
        this.type = session.tokenAcTp;
        this.name = session.tokenAc;
        Cache.setLocal(this.name, token);
        this.token = token;
        if (this.token) {
            this.tokenObj = this.getTokenObj();
            this.validateToken();
            this.changeCookieToken();
        }
    }

    set token(tk) {
        this._token = tk;
    }

    get token() {
        return this._token;
    }


    getTimeRefres(minutes: number = 1): number {
        const time_ini = new Date(this.tokenObj.exp * 1000).getTime();
        const time_fin = new Date().getTime();
        const m_miliseg = ((minutes * 60) * 1000);
        const finaltime = ((time_ini - time_fin) - m_miliseg);
        if (finaltime <= 0) {
            return 0;
        } else {
            return finaltime;
        }
    }

    isExpire(tk: string): boolean {
        return !this.isTokenExpired(tk);
    }

    getToken(): Promise<string> {
        return new Promise((success, error) => {
            if (this.token) {
                this.validateAllToken();
                this.changeCookieToken();
                success(this.token);
            } else {
                error(false);

            }
        });
    }

    isTokenExpired(token, offsetSeconds=0) {
        if (!token || token === '') {
            return true;
        }
        const date = this.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;
        if (date === null) {
            return false;
        }
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }


    getTokenObj(): object {
        return this.decodeToken(this.token);
    }

    getTokenExpirationDate(token) {
        let decoded;
        decoded = this.decodeToken(token);
        if (!decoded || !decoded.hasOwnProperty('exp')) {
            return null;
        }
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    decodeToken(token) {
        if (!token || token === '') {
            return null;
        }
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error(`The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.`);
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token.');
        }
        return JSON.parse(decoded);
    }

    urlBase64Decode(str) {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += '==';
                break;
            }
            case 3: {
                output += '=';
                break;
            }
            default: {
                throw new Error('Illegal base64url string!');
            }
        }
        return this.b64DecodeUnicode(output);
    }

    b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map
            .call(this.b64decode(str), (c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
    }

    b64decode(str) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let output = '';
        str = String(str).replace(/=+$/, '');
        if (str.length % 4 === 1) {
            throw new Error(`'atob' failed: The string to be decoded is not correctly encoded.`);
        }
        for (
        // initialize result and counters
        let bc = 0, bs, buffer, idx = 0; 
        // get next character
        (buffer = str.charAt(idx++)); 
        // character found in table? initialize bit storage and add its ascii value;
        ~buffer &&
            ((bs = bc % 4 ? bs * 64 + buffer : buffer),
                // and if not first of each 4 characters,
                // convert the first 8 bits to one ascii character
                bc++ % 4)
            ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
            : 0) {
            // try to find character in table (0-63, not found => -1)
            buffer = chars.indexOf(buffer);
        }
        return output;
    }

    validateAllToken(): boolean | string | object {
        return this.validateAllTokenBa(this.token);
    }

    validateAllTokenBa(tk: string): boolean | string | object {
        try {
            if (tk) {
                if (this.isExpire(tk)) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {
            return false;
        }
    }

    changeCookieToken() {
        Cache.changeCookie(this.type, this.token);
    }

    validateToken(): boolean | string | object {
        return this.validateAllToken();
    }
}
