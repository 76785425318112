export const category_send = [
    {
        name: 'facturacion',
        icon: 'fa fa-file-text',
        text: 'Facturas por enviar',
        color_number: 'primary',
        depends: null
    }, 
    {
        name: 'cobranzas',
        icon: 'fa fa-usd',
        text: 'Cobranzas por enviar',
        color_number: 'primary',
        depends: null
    },
    {
        name: 'pedidos',
        icon: 'fa fa-file-text',
        text: 'Pedidos por enviar',
        color_number: 'primary',
        depends: null
    }
]