import { Component, OnInit } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {

  customClass = 'customClass';
  isFirstOpen1 = true;
  isFirstOpen2 = true;
  isFirstOpen3 = true;
  isFirstOpen4 = true;
  isFirstOpen5 = true;
  isFirstOpen6 = true;

  constructor() { }

  ngOnInit() {}

}
