// Angular
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modulos para dar formato al datepicker en todos los componentes
import { MatInputModule } from '@angular/material/input';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMATS } from './core/constantes/formats';

// Material
import { MaterialModule } from './material.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

// Bootstrap
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuGeneralComponent } from './core/menu-general/menu-general.component';
import { LoginComponent } from './core/login/login.component';
import { PolicyComponent } from './core/policy/policy.component';
import { AuthService } from './core/auth/auth.service';
import { UrListService } from './core/url/url.service';
import { Home } from './core/home/home';
import { AuthGuardService } from './core/guards/auth-guard-login';
import { AuthAnonymoGuard } from './core/guards/auth-anonymo.guard';
import { PermGuard } from './core/guards/perm.guard';
import { SelectRutaComponent } from './vel/select-ruta/select-ruta.component';
import { NotfoundpageComponent } from './core/notfoundpage/notfoundpage.component';
import { ExcelService } from 'src/app/core/services/excel.service';
import { TitlesService } from './core/services/titles.service';
import { ListPendientesComponent } from './core/list-pendientes/list-pendientes.component';
import { AutoSendService } from './core/services/auto-send.service';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { NotificationCompoComponent } from './core/notifications/notification-compo/notification-compo.component';
import { SendNotificationsComponent } from './core/notifications/send-notifications/send-notifications.component';
import { HTTP } from '@ionic-native/http/ngx';
import { FeedbackComponent } from './core/feedback/feedback.component';
import { ChartsModule } from 'ng2-charts';
import { ViewModalFeedComponent } from './n360/gerencia/feedback-gerencial/feedbacks/view-modal-feed/view-modal-feed.component';
import { RegistreCategoryModalComponent } from './n360/gerencia/feedback-gerencial/categories/registre-category-modal/registre-category-modal.component';
import { RefreshInvoiceComponent } from './vel/modals/refresh-invoice/refresh-invoice.component';
import { NotificationsModule } from './core/notifications/notifications.module';
import { ButtonMoreModule } from './core/button-more/button-more.module';
import { ModalGrupComponent } from 'src/app/core/notifications/send-notifications/modal-grup/modal-grup.component';
import { FormatCurrencyService } from './core/services/format-currency.service';

import { ElegirModoComponent } from './core/offon-line/elegir-modo/elegir-modo.component';
import { EsperaInnodbComponent } from './core/offon-line/espera-innodb/espera-innodb.component';
import { OffonlineGuard } from './core/guards/offonline.guard';
import { TextoService } from 'src/app/n360/heds/hed-colgate/texto.service';
import { ButtonUpModule } from './core/button-up/button-up.module';
import { DataSendModalComponent } from './vel/modals/data-send-modal/data-send-modal.component';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ConfirmTobackGuard } from './core/guards/confirm-toback.guard';
import { ConfirmTobackService } from './core/services/confirm-toback.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { MantenimientoComponent } from './core/mantenimiento/mantenimiento.component';

@NgModule({
  declarations: [
    AppComponent, 
    MenuGeneralComponent,
    LoginComponent,
    PolicyComponent,
    Home,
    SelectRutaComponent,
    NotfoundpageComponent,
    ListPendientesComponent,
    NotificationCompoComponent,
    SendNotificationsComponent,
    FeedbackComponent,
    ViewModalFeedComponent,
    RegistreCategoryModalComponent,
    RefreshInvoiceComponent,
    ModalGrupComponent,
    ElegirModoComponent,
    EsperaInnodbComponent,
    DataSendModalComponent,
    MantenimientoComponent

  ],
  entryComponents: [ListPendientesComponent, DataSendModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot(), 
    MaterialModule, 
    BrowserAnimationsModule, 
    TranslateModule.forRoot(),
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ModalModule.forRoot(),
    MatSelectModule,
    ChartsModule,
    ButtonMoreModule,
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    NotificationsModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    MatExpansionModule,
    MatInputModule,
    MomentDateModule,
    ButtonUpModule
  ],
  providers: [
    UrListService,
    AuthService,
    AuthGuardService,
    AuthAnonymoGuard,
    AutoSendService,
    StatusBar,
    SplashScreen,
    PermGuard,
    ExcelService,
    TitlesService,
    BsModalRef,
    HTTP,
    File,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FeedbackComponent,
    ViewModalFeedComponent,
    RegistreCategoryModalComponent,
    RefreshInvoiceComponent,
    ModalGrupComponent,
    FormatCurrencyService,
    TextoService,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    OffonlineGuard,
    BarcodeScanner,
    ConfirmTobackGuard,
    ConfirmTobackService,
    LocalNotifications
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NotfoundpageComponent]
})
export class AppModule {}
