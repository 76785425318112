import { Component, OnInit } from '@angular/core';
import { InvoiceDuplicatedService } from 'src/app/core/services/invoice-duplicated.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SendDataService } from 'src/app/vel/synchronization/send-data.service';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';
import { DataVelService } from 'src/app/vel/synchronization/data-vel.service';
import { SyncService } from 'src/app/vel/synchronization/sync.service';
import { Cache } from 'src/app/core/utils/cache';
import { DatatempService } from 'src/app/vel/datatemp/datatemp.service';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import * as moment from 'moment';
import { session } from 'src/app/core/constantes/sessiones';
import { ActFacturaService } from 'src/app/core/services/act-factura.service';
import { RefreshInvoiceComponent } from 'src/app/vel/modals/refresh-invoice/refresh-invoice.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-duplicated',
  templateUrl: './invoice-duplicated.component.html',
  styleUrls: ['./invoice-duplicated.component.scss'],
})
export class InvoiceDuplicatedComponent implements OnInit {  

  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  NUMEROFACTURA: any;
  NUMEROFACTURAORIGINAL: any;
  listInvoices: any;
  invoice: any;
  loading: boolean;
  act_factura: any = {
    TIPO: 'FA',
    DESCDOCU: 'FACTURA',
    LETRA: 'A',
    NUMERO: 1,
    ULTIFECH: '',
    NUMECONT: 1,
    RUTA: ''
  };
  load = Swal.mixin({
    title: 'Verificando su operación...',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
  });

  constructor(public InvoiceDuplicatedService: InvoiceDuplicatedService, public auth: AuthService,
  	private modalService: BsModalService, public sendDataSv: SendDataService, 
  	public dataVs: DataVelService, public DatatempService: DatatempService,
  	private listUrl: UrListService, public ActFacturaService: ActFacturaService) { }

  async ngOnInit() {
  	this.listInvoices = this.InvoiceDuplicatedService.listInvoices;
    this.ActFacturaService.modal = RefreshInvoiceComponent;
    this.ActFacturaService.parentComponent = this;
    this.act_factura.RUTA = this.auth.user.id_ruta;
    this.act_factura.ULTIFECH = moment().format('YYYY-MM-DD');
    this.ActFacturaService.goToCatalog = true;
    this.loadInvoices();
    this.ActFacturaService.camps_hasChanged.subscribe(resp => {
      this.loadInvoices();
    });
    this.ActFacturaService.closedModal.subscribe(resp => {
      this.closeModal();
    });
  }

  showControlFactModal(): void {
    
    Swal.fire({
      title: 'Antes de proceder debe establecer el control de factura.',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Continuar`,
      denyButtonText: 'Salir'
    }).then((result) => {
      if (result.isConfirmed) {
          let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false
          }
          this.bsModalRef = this.modalService.show(this.ActFacturaService.modal, config);
          this.bsModalRef.setClass('modal-dialog-centered zindex-one');
          this.ActFacturaService.closeModal = this.bsModalRef;
      }
      else if (result.isDenied) {
        this.closeModal();
      }
    });
  }

  async loadInvoices() {
    if (this.auth.offonline) {
      this.loading = true;
      await this.DatatempService.get_data(['facturas', 'act_factura']).then(resp => {
         this.invoice = resp['facturas'];
         this.act_factura = resp['act_factura'][0];
      });
      this.loading = false;
    } else {
      this.invoice = this.dataVs.facturas;
      this.act_factura = this.dataVs.act_factura;
    }
    if (this.act_factura == undefined || this.act_factura == null) {
      this.showControlFactModal();
    }
  }

  closeModal(): void {
    this.InvoiceDuplicatedService.closeModal.hide();
  }

  openModalEdit(numefact: any, template: any): void {
  	this.NUMEROFACTURA = numefact;
    this.NUMEROFACTURAORIGINAL = numefact;
  	this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered zindex-one' }));
  }

  closeModalEdit(): void {
  	this.modalRef.hide();
  }

  async deleteInvoice(numefact: any) {
  	Swal.fire({
      text: 'Se eliminará la factura de la lista.',
      title: '¿Desea Continuar?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Aceptar`,
      denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete_elements(numefact);
       	}
    })
  }

  async delete_elements(numefact: any) {
    this.listInvoices = this.listInvoices.filter(x => x.numfact != numefact);
    this.invoice = this.invoice.filter(x => x.NUMEROFACTURA != numefact);
  	if (this.auth.offonline) {
      this.load.fire();
      await this.DatatempService.delete_data(['facturas']);
      if (this.invoice.length > 0) {
        let arr = Array();
        for (let i of this.invoice) {
          arr.push(
            {
              ruta: this.auth.user.id_ruta,
              data_type: 'facturas',
              modify: false,
              data_json: i
            }
          );
        }
        await this.DatatempService.add_many_data(arr);
      }
      this.load.close();
    } else {
      this.dataVs.facturas = this.invoice;
    }
    if (this.listInvoices.length == 0 || this.invoice.length == 0) {
        this.closeModal();
    }
  }

  async renameInvoice() {
    if (this.listInvoices.filter(x => x.numfact == this.NUMEROFACTURA).length > 0 || this.invoice.filter(x => x.NUMEROFACTURA == this.NUMEROFACTURA).length > 0){
      Swal.fire(
        'Error',
        'El número de Factura introducido ya existe.',
        'error'
      );
      return;
    }
    let factura = this.listInvoices.find(x => x.numfact == this.NUMEROFACTURAORIGINAL);
    let obj = Object.assign({}, factura);
    obj.numfact = this.NUMEROFACTURA;
    if (!this.configInvoices([obj], 1)) {
      Swal.fire(
        'Error',
        'Por favor, introduzca un número de Factura con formato válido.',
        'error'
      );
      return;
    }
  	let inv = this.invoice.find(x => x.NUMEROFACTURA == this.NUMEROFACTURAORIGINAL);
  	inv.NUMEROFACTURA = this.NUMEROFACTURA;
    factura.numfact = this.NUMEROFACTURA;
    if (this.auth.offonline) {
      this.load.fire();
      await this.DatatempService.delete_data(['facturas']);
      let arr = Array();
      for (let i of this.invoice) {
        arr.push(
          {
            ruta: this.auth.user.id_ruta,
            data_type: 'facturas',
            modify: false,
            data_json: i
          }
        );
      }
      await this.DatatempService.add_many_data(arr);
      this.load.close();
    } else {
      this.dataVs.facturas = this.invoice;
    }
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'El número de factura fue modificado',
      showConfirmButton: false,
      timer: 1500
    });
    this.closeModalEdit();
  }

  configInvoices(obj: any, type: number): boolean {

    let numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  	let mo = {numero: '0', letra: ''};
  	for (let a of obj) {
      if (a.numfact.length < 9) {
        return false;
      }
  	  let arr = a.numfact.split("");
  	  let numer = '';
      let le = '';
      if (!numbers.includes(arr[0]) && arr[0] != '0') {
        le = arr[0];
      } else {
        return false;
      }
  	  let has_digit = false;
  	  for (let y of arr) {
  	    if ((y != '0' && y != le ) || has_digit) {
          if (!has_digit && numbers.includes(y)) {
            numer = numer + y;
            has_digit = true;
            continue;
          }
          if ((numbers.includes(y) || y == '0') && has_digit) {
            numer = numer + y;
          } else {
            return false;
          }
  	    }
  	  }
  	  if (Number(numer) >= Number(mo.numero)) {
  	    mo.numero = numer;
  	    mo.letra = le;
  	  }
  	}
    if (mo.numero == '0' || mo.letra == '' || numbers.includes(mo.letra) || mo.letra == '0') {
      return false;
    }
    if (type == 2) {
      this.act_factura.LETRA = mo.letra;
      this.act_factura.NUMERO = Number(mo.numero);
    }
    return true;
  }

  async sendInvoices() {
    if (this.configInvoices(this.listInvoices, 2)) {
      this.act_factura['NUMERO'] = Number(this.act_factura['NUMERO']) + 1;
      if (this.auth.offonline) {
        this.load.fire();
        let arr = 
          {
              ruta: this.auth.user.id_ruta,
              data_type: 'act_factura',
              modify: true,
              data_json: this.act_factura
          };
        await this.DatatempService.add_many_data([arr]);
        this.load.close();
      } else {
        this.dataVs.act_factura = this.act_factura;
      }
      this.sendDataSv.startSendData();
      this.closeModal();
    } else {
      Swal.fire(
        'Error',
        'Por favor, introduzca los números de Factura con formatos válidos.',
        'error'
      );
    }
  }
}
