import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-up',
  templateUrl: './button-up.component.html',
  styleUrls: ['./button-up.component.scss'],
})
export class ButtonUpComponent implements OnInit {
  @Input() show_totop = true;
  @Input() element: any;

  constructor() { }

  ngOnInit() {}

  gotop(){
    if(this.element){
      this.element.scrollTo(0,0);
    }
  }

}
