import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './core/login/login.component';
import { ElegirModoComponent } from './core/offon-line/elegir-modo/elegir-modo.component';
import { EsperaInnodbComponent } from './core/offon-line/espera-innodb/espera-innodb.component';
import { PolicyComponent } from './core/policy/policy.component';

import { AuthAnonymoGuard } from './core/guards/auth-anonymo.guard';
import { AuthGuardService } from './core/guards/auth-guard-login';
import { PermGuard } from './core/guards/perm.guard';
import { NotfoundpageComponent } from './core/notfoundpage/notfoundpage.component';
import { MenuGeneralComponent } from './core/menu-general/menu-general.component';

import { rutabase as rb } from './core/constantes/rutas';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ AuthAnonymoGuard ]
  },
  {
    path: 'policy',
    component: PolicyComponent,
    canActivate: [ AuthAnonymoGuard ]
  },
  {
    path: rb.elegir_modo,
    component: ElegirModoComponent,
    canActivate: [ AuthGuardService, PermGuard ],
    data: { nobackto: false },
  },
  {
    path: rb.espera_indb,
    component: EsperaInnodbComponent,
    canActivate: [ AuthGuardService, PermGuard ],
    data: { nobackto: false },
  },
  {
    path: '',
    component: MenuGeneralComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./n360/n360.module').then(m => m.N360Module),
      },
      {
        path: rb.btdp,
        loadChildren: () => import('./vel/vel.module').then(m => m.VelModule),
      },
      {
        path: rb.notfound,
        component: NotfoundpageComponent,
        data: { titlebar: 'ERROR 404', hideaside: true },
      },
      {
        path: '**',
        redirectTo: rb.notfound
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
