import { Component, OnInit, ViewChild } from '@angular/core';

import {  Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../auth/auth.service';
import { TitlesService } from '../services/titles.service';
import { AutoSendService } from '../services/auto-send.service';
import { SyncService } from 'src/app/vel/synchronization/sync.service';
import { SendDataService } from 'src/app/vel/synchronization/send-data.service';
import { FeedbackService } from '../services/feedback.service';
import { ActFacturaService } from '../services/act-factura.service';
import { NotificacionService } from '../notifications/notificacion.service';
import { DataVelService } from 'src/app/vel/synchronization/data-vel.service';
import { RefreshInvoiceComponent } from 'src/app/vel/modals/refresh-invoice/refresh-invoice.component';
import { ListPendientesComponent } from '../list-pendientes/list-pendientes.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { FormatCurrencyService } from '../services/format-currency.service';
import { InitDateService } from 'src/app/core/init-date/init-date.service';
import { Cache } from 'src/app/core/utils/cache';
import { session } from 'src/app/core/constantes/sessiones';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { InvoiceDuplicatedService } from 'src/app/core/services/invoice-duplicated.service';
import { InvoiceDuplicatedComponent } from 'src/app/vel/modals/invoice-duplicated/invoice-duplicated.component';

import { rutasUrl } from '../constantes/rutas';
import { Utiliti } from '../viewloader/view-loader';
import { DataSendModalComponent } from 'src/app/vel/modals/data-send-modal/data-send-modal.component';
import { ConfirmTobackService } from '../services/confirm-toback.service';

@Component({
  selector: 'app-menu-general',
  templateUrl: './menu-general.component.html',
  styleUrls: ['./menu-general.component.scss'],
})
export class MenuGeneralComponent extends Utiliti implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  bsModalRef: BsModalRef;
  seller: string;
  empresa:string;
  year: string;
  rutaselected: string;
  infoupdated = false;
  isCollapsed = false;
  allrutas = rutasUrl;
  tdpurl = rutasUrl.hometdp;

  aside_apps: any;
  home = {
    idapp: 0,
    ruta:  '',
    name:  'Inicio',
    png: 'home.png',
    modulos: [
      {
        ruta: ''
      }
    ]
  };


  constructor(
    public route: Router,
    public auth: AuthService,
    public titles: TitlesService,
    public modalService: BsModalService,
    public send: AutoSendService,
    public _sync: SyncService,
    public sendDataSv: SendDataService,
    public dataVs: DataVelService,
    public NotificacionService: NotificacionService,
    public ActFacturaService: ActFacturaService,
    public mybsModalRef: BsModalRef,
    public FeedbackService: FeedbackService,
    public invbsModalRef: BsModalRef,
    public dupbsModalRef: BsModalRef,
    public InitDateService: InitDateService,
    // public fcurrency: FormatCurrencyService,
    public InvoiceDuplicatedService: InvoiceDuplicatedService,
    public logoutbsModalRef: BsModalRef,
    public toback: ConfirmTobackService
  ){
    super();
    this.activar = true;
    this.ActFacturaService.modal = RefreshInvoiceComponent;
    this.ActFacturaService.parentComponent = this;
    this.year = new Date().getFullYear().toString();
    this.aside_apps = [];
    if (this.auth.isLogin()) {
      this.organizaAppmodulo(this.auth.user.apps, this.auth.user.modulos, this.auth.user.apps_groups);
    }
    this.titles.even_sync_appsmodulos.subscribe( (resp)=>{
      this.organizaAppmodulo(resp.apps, resp.modulos, resp.apps_groups);
    });
    this.InitDateService.startIntervalTime();
    this.InvoiceDuplicatedService.modal = InvoiceDuplicatedComponent;
    this.InvoiceDuplicatedService.openModal_emitter.subscribe(resp => {
      Swal.fire({
        title: 'Error al enviar datos de facturas',
        text: 'Existen registros duplicados de números de factura ¿Desea editar los números de Factura?',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: `Editar`,
        denyButtonText: 'Salir'
      }).then((result) => {
        if (result.isConfirmed) {
          this.openModalDuplicatedInvoice(resp);
        }
      });
      
    });
    // this.fcurrency.syncFormats(false);
  }

  ngOnInit(){
  }

  get date() {
    let cache =Cache.getLocal(session.init_date, true);
    if (cache != undefined && cache != null) {
      let momento = moment(cache.strdate);
      momento.locale('es');
      return momento.format("ddd DD/MM/YYYY hh:mma");
    }
    return '';
  }



  get list_pendientes() {
    const close = this.send.pendd();
    if (close == false && typeof this.bsModalRef !== 'undefined' && this.bsModalRef) {
      this.bsModalRef.hide();
    }
    return close;
  }
  
  get titlebar(): string{
    return this.titles.titlebar;
  }
  
  get isLoading():boolean{
    return this.titles.loadingbar;
  }

  get modo():string{
    if(this.auth.modeoffonline){
      return this.auth.offonline ? 'OnLine' : 'OffLine';
    }else{
      return "Sin Elegir";
    }
  }

  get isOnline():boolean{
    if(this.auth.modeoffonline){
      return this.auth.offonline;
    }
    return true;
  }

  open_modal() {
    this.bsModalRef = this.modalService.show(ListPendientesComponent, {backdrop: 'static'});
    this.bsModalRef.setClass('modal-lg');
  }

  updateInfo(dat=1) {
    // 1= Seller, 2= Empresa, 3=ruta 
    let usr = Object.assign(this.auth.user);
    if(dat==1){
      this.seller = usr.nombre_user + ' ' + usr.apellido_user;
      if(this.seller.length>70){
        this.seller = this.seller.slice(0,70)+'...';
      }
      return this.seller;
    }
    if(dat==2){
      let name_empresa = usr.nombre_sucu;
      if (typeof name_empresa == 'string' && name_empresa.trim() != ''){
        this.empresa = name_empresa;
      }else{
        this.empresa = "No se encontro empresa";
      }
      if(this.empresa.length>70){
        this.empresa = this.empresa.slice(0,70)+'...';
      }
      return this.empresa;
    }
    if(dat==3){
      this.rutaselected = usr.id_ruta!='' ? usr.id_ruta : "Sin Ruta selecionada" ;
      return this.rutaselected;
    }
  }

  openInvoiceConfigModal() : void{
    this.invbsModalRef = this.modalService.show(this.ActFacturaService.modal);
    this.invbsModalRef.setClass('modal-dialog-centered zindex-one');
    this.ActFacturaService.closeModal = this.invbsModalRef;
  }

  openModalDuplicatedInvoice(resp: any): void {
    this.InvoiceDuplicatedService.last_insert = resp.last_insert;
    this.InvoiceDuplicatedService.listInvoices = resp.Errores;
    this.InvoiceDuplicatedService.closeModal = this.modalService;
    this.dupbsModalRef = this.modalService.show(this.InvoiceDuplicatedService.modal);
    this.dupbsModalRef.setClass('modal-dialog-centered zindex-one');
  }

  init_sync(value) {
    this.sidenav.close();
    this._sync.startSync(value, false);
  }

  sendData() {
    this.sidenav.close();
    this.sendDataSv.startSendData();
  }

  showFeedBack() {
    this.sidenav.close();
    this.mybsModalRef = this.modalService.show(FeedbackComponent);
    this.mybsModalRef.setClass('modal-dialog-centered');
    this.FeedbackService.closeModal = this.mybsModalRef;
  }

  openMenu(){
    this.sidenav.toggle();
  }

  gotoback(){
    let url = this.titles.gotoback();
    if(url){
      this.route.navigateByUrl(url);
    }
    this.sidenav.close();
  }

  notificacionLeidas(){
    this.sidenav.close();
    this.NotificacionService.notificacion_leidas();
  }

  // syncMoneda(){
  //   this.sidenav.close();
  //   this.fcurrency.syncFormats();
  // }

  dirige(app){
      if(app.modulos.length == 1){
        this.route.navigate([app.modulos[0].ruta]);
      }else{
        this.route.navigate(['/home/'+app.idapp]);
      }
  }

  formatoNgfor(vari, tipo=true){
    // tipo: true para apps, false para modulos
    if(tipo){//para apps
      return {
        idapp: vari.idapp,
        idgroup: vari.idapps_group,
        ruta:  vari.ruta,
        name:  vari.nombre_app,
        png: vari.imagen_app,
        modulos: [],
        orden: vari.orden_show,
      };
    }else{//para modulos
      return {
        idapp: false,
        ruta:  vari.ruta_modulo,
        png: vari.imagen_modulo,
        orden: vari.orden_show,
      };
    }
  }

  formatoNgforGroup(vari){
    return {
      idapp: 1000 + vari.idapps_group,
      idgroup: vari.idapps_group,
      ruta:  '',
      name:  vari.nombre_group,
      png: vari.imagen_group,
      modulos: [],
      orden: vari.orden_show,
    };
  }

  organizaAppmodulo(apps,moduls,grup){
    let gropo = grup.reduce((arar: any,gr: any) => {
      arar.push(this.formatoNgforGroup(gr));
      return arar;
    }, []);
    if(Array.isArray(apps) && Array.isArray(moduls)){
      let todo = apps.reduce((ar: any,app: any) => {
        let modulos = moduls.reduce((arr: any,modu: any) => {
          if (modu.aplicaciones_idapp == app.idapp){
            arr.push(this.formatoNgfor(modu,false));
          }
          return arr;
        }, []);
        let appp = this.formatoNgfor(app);
        modulos.sort((a,b)=>a.orden-b.orden);
        appp.modulos = modulos;
        if(appp.idgroup){
          let gk = gropo.findIndex( gg => { return gg.idgroup == appp.idgroup;  });
          gropo[gk].modulos.push(appp);
        }else{
          ar.push(appp);
        }
        return ar;
      }, []);
      gropo.reduce((arr: any,gr: any) => {
        if(gr.modulos.length > 0){
          todo.push(gr);
        }
        return arr;
      }, []);
      todo.sort((a,b)=>a.orden-b.orden);
      this.aside_apps = todo;
    }
  }

  isAsideactive(app){
    if(app == this.titles.selected_aside_menu){
      return ''
    }
    return 'inactive'
  }

  logout(){
    if (this.auth.isLogin()) {
      if (this.dataVs.poseeDatosPorEnviar(true)) {
        const config: any = {
          initialState: {    
            tipo:'logout',
            sendata: this.sendDataSv,
          }
        }
        this.logoutbsModalRef = this.modalService.show(DataSendModalComponent, config);
        this.logoutbsModalRef.content.even_send_data.subscribe(resp => {
          if(resp){
            setTimeout(() => {
              this.auth.logout();
            }, 500);
          }
        });
      } else {
        this.auth.logout();
      }
    } else {
      this.auth.logout();
    }
  }

}
