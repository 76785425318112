import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { session } from '../constantes/sessiones';
import { Cache } from '../utils/cache';
import { Config, Config2 } from '../interfaces/config';
import { Init } from '../interfaces/init';
import { UrListService } from '../url/url.service';
import { Ajax } from '../utils/ajax';
import { formatCurrency as fC, registerLocaleData } from '@angular/common';

import localeUs from '@angular/common/locales/en-US-POSIX';
import localeVe from '@angular/common/locales/es-VE';
import { Moneda, Monedas } from '../interfaces/vel/monedas';
import { TitlesService } from './titles.service';
import Ajax2 from '../utils/ajax2';

registerLocaleData(localeVe, localeUs);

@Injectable({
  providedIn: 'root'
})
export class FormatCurrencyService {
  // Transforma un dato numerico al formato selecionado, ademas de mostrar el simbolo de la moneda
  //Valores por defecto en caso de que falle el request por alguna razon:
  decimilesDefault = 'en-US';
  simboloPrimera = 'Bs ';
  simboloSegunda = '$ ';
  codigoPrimera = '01';
  codigoSegunda = '02';
  err_codePrimera = 0;// si es 6 entonces no existe el registro
  err_codeSegunda = 0;// si es 6 entonces no existe el registro
  miles = ',';
  decimal = '.';
  decimilesFormato = {
    'en-US':{
      miles: ',',
      decimal: '.',
    },
    'es-VE':{
      miles: '.',
      decimal: ',',
    }
  }

  monedas:Monedas;
  moneda_p:Moneda;

  pass_sync = true; //True Permite buscar los datos de la moneda. False Impide que se hagan mas request hasta que se termine el actual


  constructor(
    public auth: AuthService,
    private listUrl: UrListService,
    public titles: TitlesService
  ){
    this.syncFormats(false, titles);
  }

  resetFormats(){
    this.decimilesDefault = 'en-US';
    this.simboloPrimera = 'Bs ';
    this.simboloSegunda = '$ ';
    this.codigoPrimera = '01';
    this.codigoSegunda = '02';
    this.err_codePrimera = 0;
    this.err_codeSegunda = 0;
    this.miles = ',';
    this.decimal = '.';
    this.decimilesFormato = {
      'en-US':{
        miles: ',',
        decimal: '.',
      },
      'es-VE':{
        miles: '.',
        decimal: ',',
      }
    }

    this.monedas = [] as Monedas;
    this.moneda_p = {} as Moneda;
  }

  syncFormats(visible=true, titles:any={}, force=true){
    this.getFormats(visible,titles,force).then(resp => {
      this.decimilesDefault = resp.salida.locale;
      this.miles = this.decimilesFormato[this.decimilesDefault].miles;
      this.decimal = this.decimilesFormato[this.decimilesDefault].decimal;
      this.simboloPrimera = resp.salida.mone1.simbolo+' ';
      this.simboloSegunda = resp.salida.mone2.simbolo+' ';
      this.codigoPrimera = resp.salida.mone1.code;
      this.codigoSegunda = resp.salida.mone2.code;
      this.err_codePrimera = resp.salida.mone1.err_code;
      this.err_codeSegunda = resp.salida.mone2.err_code;
      this.monedas = resp.monedas;
      this.moneda_p = resp.moneda_p;
      Cache.setLocal(session.has_fc, true);
      this.pass_sync = true;
    }).catch(error => {
      this.pass_sync = true;
    });
  }

  getFormats(alerts=true, titles:any={}, force_update=false){//Trae el formato del back
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('syncFormats'),
      auth: this.auth,
      body: {}
    };
    const configAjax: Config2 = {
      alerts,
      titles,
      force_update,
      retry_requests: 1,
      params: [
        {name: session.fmones, find: 'salida'},
        {name: session.monedas, find: 'monedas'},
        {name: session.moneda_p, find: 'moneda_p'},
      ]
    };
    const ajax = new Ajax2(initAjax, configAjax);
    return ajax.call();
  }

  setFormats( body={}, alerts=true, titles:any={}){//Asigna el formato a una sucursal
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('setFormats'),
      auth: this.auth,
      body
    };
    const configAjax: Config2 = {
      alerts,
      titles,
      retry_requests: 1,
    };
    const ajax = new Ajax2(initAjax, configAjax);
    return ajax.call();
  }

  formatCurrency(nume, locale, simbolo, deci){
    if(Cache.getLocal(session.has_fc, true) == null && this.pass_sync){
      this.pass_sync = false;
      this.syncFormats(false, this.titles);
    }
    return fC(nume, locale, simbolo,'', deci);
  }

  fCurrency(nume, deci='1.2-2'){//Formatea 
    return this.formatCurrency(nume, this.decimilesDefault, this.simboloPrimera,deci);
  }

  fCurrencyDivisa(nume, deci='1.2-2'){//Formatea un monto de la moneda secundaria
    if(this.hasDivisa()){
      return this.formatCurrency(nume, this.decimilesDefault, this.simboloSegunda,deci);
    }else{
      return this.formatCurrency(0, this.decimilesDefault, ' ',deci);
    }
  }

  fCurrencyCustom(nume, simbolo="$ ", deci='1.2-2'){//Formatea segun monto y simbolo dado
    return this.formatCurrency(nume, this.decimilesDefault, simbolo,deci);
  }

  hasDivisa(){//Verifica si existe la moneda secundaria
    if(this.err_codeSegunda == 6){// Si es igual a 6 quiere decir que no existe el registro
      return false;
    }else{
      return true;
    }
  }

  redondeaFloat(monto: number, deci=2): number{
    //realiza un redondeo a la cantidad de deciamles dados.
    return parseFloat(monto.toFixed(deci));
  }

}
