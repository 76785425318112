import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SyncService } from 'src/app/vel/synchronization/sync.service';
import { AuthService } from '../../auth/auth.service';
import { inndb } from '../../constantes/sessiones';
import { rutasUrl } from '../../constantes/rutas';
import { Cache } from '../../utils/cache';

@Component({
  selector: 'app-espera-innodb',
  templateUrl: './espera-innodb.component.html',
  styleUrls: ['./espera-innodb.component.scss'],
})
export class EsperaInnodbComponent implements OnInit {

  dbv = 1;
  intento = 0;

  constructor(
    public auth: AuthService,
    public router: Router,
    public sync: SyncService
  ) { 
  }
  
  ngOnInit() {
    this.init_innodb();
  }

  init_innodb(){
    // console.log('nuevo init version', this.dbv);
    this.dbv = Cache.getDbv();
    Cache.create_db(inndb.namedb, this.dbv).then( async (resp)=>{
      try {
        this.esperaSave();
      } catch (error) {
        this.recallInit();
      }
    }).catch(err => {
      this.recallInit();
    });
  }

  recallInit(){
    this.dbv++;
    // console.log('nuevo recall version', this.dbv);
    Cache.setDbv(this.dbv);
    this.init_innodb();
  }

  esperaSave(){
    // console.log('intento numero', this.intento);
    this.intento++;
    if (this.intento < 5) {
      Cache.save_data_db('prinit', ['prinit'], inndb.namedb, this.dbv).then( resp =>{
        this.auth.cargadaiDB = true;
        this.auth.guardaModos();
        setTimeout(() => {
          if(this.auth.isRuta()){
            this.sync.startSync('all', false);
          }
          this.router.navigate([rutasUrl.hometdp]);
        }, 1000);
      }).catch( err=>{
        setTimeout(() => {
          this.esperaSave();
        }, 700);
      });
    }else{
      // console.log('5 intentos fallidos, reincia al init');
      this.intento = 0;
      this.recallInit();
    }
  }

  getId(){
    return 58;
  }

}
